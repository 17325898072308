import React, { useState } from 'react'
import classNames from 'classnames'
import { Form, Row, Col } from 'react-bootstrap'
import { ReactComponent as MailIcon } from '../../assets/icons/envelope.svg'
import './style.scss'

const Input = ({ id, name = "", label = "", as = "input", maxLength = 50000, type, value, onChange, required = false, error = "" }) => {
  return (
    <Form.Group controlId={id} className={classNames({ 'd-none': type === 'hidden' })}>
      <Form.Label>{label || id}</Form.Label>
      <Form.Control
        required={required}
        className={classNames({ error: error })}
        as={as}
        name={name || id}
        type={type || 'text'}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">
        {label} is required!
      </Form.Control.Feedback>
    </Form.Group>
  )
}

const NewsletterBox = ({ type = 'A' }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  if (type === 'A') {
    return (
      <div className="newsletter-box-A">
        {!isOpenForm && (
          <>
            <div className="white-border" onClick={() => setOpenForm(true)}>
              <span>
                SIGN UP FOR THE AQUIENT MONTHLY NEWSLETTER
              </span>
            </div>
            <div className="input-button" onClick={() => setOpenForm(true)}>
              <MailIcon fill="#fff" />
              <label>Enter your Email Address</label>
            </div>
          </>
        )}
        {isOpenForm && (
          <Form
            action="https://aquient.us19.list-manage.com/subscribe/post?u=1238d0b5c797bddf76a99c0c0&amp;id=9b37fb447c"
            method="post"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            target="_blank"
          >
            <Input
              required
              id="email"
              label="Email"
              name="EMAIL"
              type="email"
              maxLength="80"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Input
              id="first_name"
              label="First Name"
              name="FNAME"
              type="text"
              maxLength="80"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <Input
              id="last_name"
              label="Last Name"
              name="LNAME"
              type="text"
              maxLength="80"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            <button type="submit">
              SIGN UP
            </button>
          </Form>
        )}
      </div>
    )
  }
  return (
    <div className="newsletter-box-B">
      <div className="white-border">
        {!isOpenForm && (
          <>
            <span>
              Sign up for the Aquient Monthly Newsletter!
            </span>
            <div className="input-button" onClick={() => setOpenForm(true)}>
              <label>ENTER YOUR EMAIL ADDRESS</label>
              <div className="input" />
            </div>
          </>
        )}
        {isOpenForm && (
          <>
            <Form
              action="https://aquient.us19.list-manage.com/subscribe/post?u=1238d0b5c797bddf76a99c0c0&amp;id=9b37fb447c"
              method="post"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              target="_blank"
            >
              <Row>
                <Col md={4}>
                  <Input
                    required
                    id="email"
                    label="ENTER YOUR EMAIL ADDRESS"
                    name="EMAIL"
                    type="email"
                    maxLength="80"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Input
                    id="first_name"
                    label="FIRST NAME"
                    name="FNAME"
                    type="text"
                    maxLength="80"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Input
                    id="last_name"
                    label="LAST NAME"
                    name="LNAME"
                    type="text"
                    maxLength="80"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </Col>
              </Row>
              <div className="submit-area">
                <button type="submit">
                  SIGN UP
                </button>
              </div>
            </Form>
          </>
        )}
      </div>
    </div>
  )

}

export default NewsletterBox
