import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'
import Image1 from '../../assets/images/home-img4.jpg'
import Logo from '../../assets/images/aquient-logo-black-bluedot.png'
import LinkedinLogo from '../../assets/images/FooterIcon_LinkedIn.png'
import InstagramLogo from '../../assets/images/FooterIcon_IG.png'
import FacebookLogo from '../../assets/images/FooterIcon_Facebook.png'
import { getIsActive } from '../../helpers/index'
import './style.scss'

const defaultFooter = [
  [
    { href: '/what-we-do', name: 'OUR APPROACH' },
    { href: '/practice-areas', name: 'PRACTICE AREAS' },
    { href: '/case-studies', name: 'CASE STUDIES' },
    { href: '/platforms/mulesoft', name: 'MULESOFT' },
  ],
  [
    { href: '/platforms/salesforce', name: 'SALESFORCE' },
    // { href: '/platforms/sitecore', name: 'SITECORE' },
    { href: '/platforms/google-cloud', name: 'GOOGLE CLOUD' },
    { href: '/contact-us', name: 'CONTACT US' },
    { href: '/privacy-policy', name: 'PRIVACY POLICY' },
  ],
  [
    { href: '/industries', name: 'INDUSTRIES' },
    { href: '/careers', name: 'CAREERS' },
    { href: '/insights', name: 'INSIGHTS' },
    { href: '/insights/aquient-news', name: 'NEWS' },
  ],
]

const mobileFooter = [
  [
    { href: '/what-we-do', name: 'OUR APPROACH' },
    { href: '/practice-areas', name: 'PRACTICE AREAS' },
    { href: '/case-studies', name: 'CASE STUDIES' },
    { href: '/platforms/mulesoft', name: 'MULESOFT' },
    { href: '/platforms/salesforce', name: 'SALESFORCE' },
    { href: '/privacy-policy', name: 'PRIVACY POLICY' },
    // { href: '/platforms/sitecore', name: 'SITECORE' },
  ],
  [
    { href: '/platforms/google-cloud', name: 'GOOGLE CLOUD' },
    { href: '/contact-us', name: 'CONTACT US' },
    { href: '/industries', name: 'INDUSTRIES' },
    { href: '/careers', name: 'CAREERS' },
    { href: '/insights', name: 'INSIGHTS' },
    { href: '/insights/aquient-news', name: 'NEWS' },
  ]
]

const Footer = () => {
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight, });
  const [footerLinks, setFooterLinks] = useState(defaultFooter)

  useEffect(() => {
    function updateSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  useEffect(() => {
    if (windowSize.width <= mobileSize) {
      setFooterLinks(mobileFooter)
    } else {
      setFooterLinks(defaultFooter)
    }
  }, [windowSize])

  const mobileSize = 575;

  const { pathname } = useLocation()
  // const getIsActive = (str) => pathname.includes(str)

  
  const scrollToPosition = () => {
    if (getIsActive('/platforms',pathname) && !!pathname.replace('/platforms', '')) {
      var el = document.getElementById('partnerSection');
      return el.scrollIntoView({behavior: 'smooth'});
    }
    return window.scrollTo(0, 0)
  }

  return (
    <div className="footer">
      {!pathname.includes('contact-us') && (
        <>
          <div className="bg-black">
            <div className="container">
              <h1>Let's make your vision<br />a reality, today</h1>
              <p className="par-1">
                Aquient helps companies solve their business problems
                and build capability for the future, with solutions
                spanning business advisory, customer experience,
                technology, and analytics.
              </p>
              <Button as={Link} to="/contact-us" className="mt-3">Contact Us<i className="fas fa-chevron-right" /></Button>
            </div>
          </div>
          <div className="image-container">
            <div className="container">
              <img src={Image1} alt="contact us" />
            </div>
          </div>
        </>
      )}
      <div className="container-fluid d-flex flex-column mt-5 footer-bottom">
        <div className="logo">
          <Link to="/" onClick={scrollToPosition}>
            <img
              alt="Aquient Logo"
              src={Logo}
              className="d-inline-block align-top"/>
          </Link>
        </div>
        
        <div className={`d-flex mt-4 link-list ${windowSize.width <= mobileSize ? 'link-list-mobile' : ''}`}>
          { footerLinks.map((collinks, i) => {
              return (
                <div key={i} className={cx({
                  "d-flex": windowSize.width > mobileSize,
                  "flex-column": windowSize.width > mobileSize,
                  // "w50": windowSize.width <= mobileSize
                })}>
                  {collinks.map((link, j) => (
                    <Link
                      key={`link-${j.toString()}`}
                      className={cx({ active: getIsActive(link.href,pathname) })}
                      to={link.href}
                      onClick={() => getIsActive(link.href,pathname) ? scrollToPosition() : null}
                    ><h6>{link.name}</h6>
                    </Link>
                  ))}
                </div>
              )
            })}
        </div>
       
        <div className="text-center mt-4">
          <a className="linkedin px-3" href="https://www.instagram.com/aquient.asia/" target="_blank" rel="noopener noreferrer">
            <img src={InstagramLogo} alt="Instagram" />
          </a>
          <a className="linkedin px-3" href="https://www.facebook.com/Aquient-106994214683878/" target="_blank" rel="noopener noreferrer">
            <img src={FacebookLogo} alt="Facebook" />
          </a>
          <a className="linkedin px-3" href="https://www.linkedin.com/company/aquient" target="_blank" rel="noopener noreferrer">
            <img src={LinkedinLogo} alt="LinkedIn" />
          </a>
        </div>
        <div className="my-4 copyright"><h6 className="font-light">Copyright 2021 Aquient Pte Ltd</h6></div>
      </div>
      <div className="ornament-custom">
        <div className="wrapper">
          <div className="big-yellow" />
          <div className="circle-green" />
          <div className="fill-blue" />
          <div className="fill-yellow" />
        </div>
      </div>
    </div>
  )
}

export default Footer
