import React from 'react'
import Lottie from 'react-lottie'
import './style.scss'

const Animation = ({ data, width, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="lottie">
      <Lottie
        options={defaultOptions}
        width={width}
        height={height}
      />
    </div>
  )
}

export default Animation
