import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import MainLayout from '../../../layouts/MainLayout.js/index.js'
import ScrollToTopOnMount from '../../../routers/ScrollToTopOnMount.js'
import ItemLayoutB from '../../../components/Insights/ItemLayoutB'
import Pagination from '../../../components/Pagination'
import { ReactComponent as ArticleIcon } from '../../../assets/icons/insights/article.svg'
import { getPostsByTag, getAllCategories } from '../../../actions'
import { apiUrl } from '../../../apis'
import { getCategory, getDetailLink, getCategorySlug } from '../../../helpers'
import './style.scss'

const getTags = async () => {
  return fetch(`${apiUrl}/tags?per_page=100`, {
    method: 'GET',
  }).then(response => response.json()).then(data => {
    if (!data?.data?.status === 404 || data.length > 0) {
      return data
    }
  }).catch(err => {
    console.error('Error:', err)
  })
}

const InsightTags = React.memo((props) => {
  let query = new URLSearchParams(useLocation().search);
  const { pathname } = useLocation()
  const [ tags, setTag] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [dataByTag, setDataByTag] = useState([]);
  const [argsPage, setArgsPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const argsPerPage = 20;
  const perPage = 5;
  const queryTag = Number(query.get('id'));

  useEffect(() => {
    (async function() {
      const tags = await getTags();
      if(tags) {
        setTag(tags);
      }
      const categories = await getAllCategories();
      setCategories([...categories])
    })()
  }, [])

  useEffect(() => {
    (async function() {
      if (pathname === '/insights/tags') {
        const data = await getPostsByTag(queryTag, argsPage, argsPerPage);
        if(data && data.length) {
          setDataByTag(data);
          setArgsPage(argsPage + 1)
        } else {
          setIsFetched(true);
        }
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, queryTag])

  useEffect(() => {
    // to get all posts per page until empty
    if (argsPage > 1) {
      (async function() {
        const res = await getPostsByTag(queryTag, argsPage, argsPerPage);
        if (res && res.length) {
          setDataByTag([...dataByTag, ...res])
          setArgsPage(argsPage + 1)
        }
        else {
          setIsFetched(true);
        }
      })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsPage])

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  // const maxBlogLength = 5;
  // let filteredBlogs = queryTag ? blogs.filter(item => item.tags.includes(queryTag)) : blogs;
  // filteredBlogs = filteredBlogs.filter((item, index) => index < maxBlogLength);

  return (
    <div className="blog-page">
      <ScrollToTopOnMount />
      <MainLayout>
        <Helmet>
          <title>Aquient | Insights</title>
          <meta name="description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/blog" />
          <meta name="og:title" content="Aquient | Insights"/>
          <meta name="og:description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Insights" />
          <meta name="twitter:description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container fluid className="intro">
          <div className="blog-by-tag">
            <h1 className="text-center container">Recommended Readings on {query.get("name")}</h1>
            <hr/>
            {/* <BlogList blogCollection={filteredBlogs} tags={tags} /> */}
            {dataByTag.map((item, i) => {
              if (
                (currentPage === 1 && i < currentPage * perPage) || 
                (currentPage !== 1 && i < currentPage * perPage && i >= (currentPage - 1) * perPage)
              ) {
                return (
                  <>
                    <ItemLayoutB
                      key={item.id}
                      type={getCategory(getCategorySlug(item.categories, categories))}
                      item={item}
                      tags={tags}
                      isFirst={i === 0}
                      // link1={`/insights/articles/${item.slug}`}
                      link1={getDetailLink(getCategorySlug(item.categories, categories), item.slug, item.link)}
                    />
                    <div className="separator" />
                  </>
                );
              }
              return null
            })}
            {isFetched && !dataByTag.length && (
              <div className="empty-post">
                <ArticleIcon />
                <h3>No Posts Yet</h3>
              </div>
            )}
            {!!dataByTag.length && (
              <Pagination
                currentPage={currentPage}
                totalPage={Math.ceil(dataByTag.length / perPage)}
                onChange={onPageChange}
              />
            )}
          </div>
        </Container>
      </MainLayout>
    </div>
  )
})

// function TagTitle({ name }) {
//   return (
//     <div>
//       {name ? ( name ) : ( <div></div> )
//       }
//     </div>
//   );
// }

export default InsightTags
