import React from 'react'
import '../style.scss'

const Background4 = () => {
  return (
    <div className='banner insight-bg-4'>
        <div className="content">
            <span>Articles</span>
            <h1 className="text-center">Thoughts from the mind within</h1>
            <p className="text-center">
                Collecting the thoughts and insights of our people, regarding the current state of affairs in the industry and beyond.
            </p>
        </div>
        <div className="circle-left-3">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
        <div className="circle-left-2">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
        <div className="circle-left-1">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
        <div className="circle-right-1">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
        <div className="circle-right-2">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
        <div className="circle-right-3">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
          </div>
        </div>
    </div>
  )
}

export default Background4
