import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../../../assets/images/crm.png'
import { ReactComponent as User } from '../../../assets/icons/user-stand.svg'
import { ReactComponent as Handshake } from '../../../assets/icons/handshake.svg'
import { ReactComponent as Folder } from '../../../assets/icons/folder-profile.svg'
import { ReactComponent as Deal } from '../../../assets/icons/deal.svg'
import { ReactComponent as Loyalty } from '../../../assets/icons/loyalty.svg'
import { ReactComponent as Matrix } from '../../../assets/icons/matrix-point.svg'
import { ReactComponent as CloudDevice } from '../../../assets/icons/cloud-device.svg'
import { ReactComponent as CloudShare } from '../../../assets/icons/cloud-share.svg'
import { ReactComponent as Analytics } from '../../../assets/icons/analytics.svg'
import './style.scss'

const CRM = () => {
  return (
    <div id="crm" className="crm">
      <Container>
        <Row className="align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <h6 className="text-uppercase">PRACTICE AREAS</h6>
            <h1>CRM</h1>
            <p><b>The heart and soul of our offering.</b></p>
            <p>
              We’re best known both for transformative digital experiences that marry design and
              function, optimised for conversions
            </p>
          </Col>
          <Col lg={6} className="mb-5 mb-lg-0 text-center text-lg-left">
            <img src={img1} alt="" />
          </Col>
        </Row>
        
        <Row className="mt-5">
          <Col lg={6}>
            <h2 className="font-normal">
              Aquient’s CRM practice optimises the relationship between a customer and the brand through their
              entire lifecycle. We make every interaction with customers highly personalised and relevant.
            </h2>
          </Col>
          <Col lg={6}>
            <p>
              We can help you establish a CRM vision, build a business case for CRM, assess your current state
              customer data and technology, make recommendations on technology / data engineering, select
              vendors, build strategic roadmaps and plan your communications.
            </p>
            <p>
              So we define, implement and manage the full ecosystem for highly optimised, segmented customer
              interaction.
            </p>
          </Col>
        </Row>
        
        <Row className="mt-5">
          <h6 className="w-100 text-center mt-5">OTHER AREAS OF CRM WE CAN HELP YOU WITH</h6>
          <Col sm={6} md={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><User /></div>
            <p className="fz-37 text-center">Loyalty Programs</p>
            <p>
              We build loyalty programs that increase lifetime value, increase satisfaction and most
              importantly, ensure more brand interactions in future. If you’re providing a service, we work
              to lower churn and increase upsell. If you are selling products we find moments, messages and
              incentives to stimulate repeat purchase. We help design program mechanics, digital experiences
              and communications across email, mobile, social, point-of-sale and other channels.
            </p>
          </Col>
          <Col sm={6} md={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><Handshake /></div>
            <p className="fz-37 text-center">Customer Service</p>
            <p>
              Digital can be a highly effective customer service channel, and can lower customer service
              costs by shifting queries from phone and in-person to digital self-service (eg call
              deflection). We can help you define a wholistic customer service strategy and then create
              digital experiences designed to increase customer satisfaction while lowering customer
              service costs.
            </p>
          </Col>
          <Col sm={6} md={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><Folder /></div>
            <p className="fz-37 text-center">Customer Data</p>
            <p>
              Data is everywhere, and in huge quantities. With increased data comes the challenge of making
              it available in the right place, at the right time. Our data science and business analysis
              capabilities allow us to help brands develop data management platform (DMP) strategies and
              implementations that grow with a brand across its entire lifecycle.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>SERVICE</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Deal /></div>
            <p className="font-normal text-center"><b>CRM Strategy</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Loyalty /></div>
            <p className="font-normal text-center"><b>Loyalty Initiatives</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Matrix /></div>
            <p className="font-normal text-center"><b>Marketing Automation</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><CloudDevice /></div>
            <p className="font-normal text-center"><b>CRM Ecosystem Development</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><CloudShare /></div>
            <p className="font-normal text-center"><b>Digital Customer Service</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Analytics /></div>
            <p className="font-normal text-center"><b>Customer Data Management</b></p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CRM
