import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container, Button, Spinner } from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import { apiUrl } from '../../apis'
import './style.scss'

const Career = () => {
  const { hash } = useLocation()
  const { jobId } = useParams()
  const [ job, setJob ] = useState()
  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace('#', ''));
      el.scrollIntoView();
    }
    else window.scrollTo(0, 0)
  }, [hash])
  useEffect(() => {
    fetch(`${apiUrl}/job/${jobId}`, {
      method: 'GET',
    }).then(response => response.json()).then(data => {
      setJob(data)
    }).catch(err => {
      console.error('Error:', err)
    })
  }, [jobId])

  return (
    <div className="job-page">
      <MainLayout>
        {!job && <Container><Spinner animation="border" /></Container>}
        {!!job && <Container>
            <h6 className="text-uppercase">CAREERS</h6>
            <h1 dangerouslySetInnerHTML={{__html: job.title.rendered}}/>
            <p>Location: {job.location}</p>
            <div dangerouslySetInnerHTML={{ __html: job.content.rendered }} />
            <div>
              <Button
                as="a"
                className="apply-btn"
                href={job.applynowlink}
                target="_blank"
                rel="noopener noreferrer"
                variant="outline-primary"
              > Apply Now
              </Button>
            </div>
          </Container>
        }
      </MainLayout>
    </div>
  )
}

export default Career
