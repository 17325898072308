import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
//import { Row, Col } from 'react-bootstrap';
//import Slider from "react-slick";
//import BlogCard from '../BlogCard'
import TagList from '../TagList'
import { apiUrl } from '../../../apis'
import dateFormat from 'dateformat';
import FacebookLogo from '../../../assets/icons/Facebook.svg'
import LinkedinLogo from '../../../assets/icons/Linkedin.svg'
//import TwitterLogo from '../../../assets/icons/Twitter.svg'
import WhatsAppLogo from '../../../assets/icons/whatsapp.svg'
import EmailLogo from '../../../assets/icons/E-mail.svg'

import './style.scss'

const Blog = ({ relatedStories, tags }) => {
  const { pageId } = useParams()
  const [ story, setStory ] = useState()
  useEffect(() => {
    fetch(`${apiUrl}/posts/${pageId}`, {
      method: 'GET',
    }).then(response => response.json()).then(data => {
      setStory(data)
    }).catch(err => {
      console.error('Error:', err)
    })
    window.scrollTo(0, 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pageId])
  if (!story) return null

  const fbUrl = `https://www.facebook.com/dialog/share?app_id=544889153158229&display=popup&href=${encodeURIComponent(window.location.href)}`
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`
  //const twitterUrl = `http://www.twitter.com/share?url=${encodeURIComponent(window.location.href)}` 
  const emailUrl = `mailto:?subject=Aquient Insights&body=Check this out from the Aquient website: ${encodeURIComponent(window.location.href)}`
  const whatsappUrl = `https://wa.me?text=${encodeURIComponent(window.location.href)}`

  const blogTag = [];
  for (let i = 0; i < tags.length; i++) {
    const currentTag = tags[i];
    if (story.tags.includes(currentTag.id)) {
      blogTag.push(currentTag);
    }
  }

  const {
    title,
    content,
    date,
  } = story;
  return ( 
    <div className="story">
      <h1>{title.rendered}</h1>
      <div className="text-left mb-5" dangerouslySetInnerHTML={{ __html: content.rendered }} />
      <div>
        <strong>{dateFormat(date, "mmmm dS, yyyy")}</strong>
        <div><TagList tagCollection={blogTag} />  </div>
        <div className="mt-4">
          <a className="logo" href={fbUrl} target="_blank" rel="noopener noreferrer">
            <img src={FacebookLogo} alt="Facebook" />
          </a>
          <a className="logo" href={linkedinUrl} target="_blank" rel="noopener noreferrer">
            <img src={LinkedinLogo} alt="LinkedIn" />
          </a>
          {/*<a className="logo" href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <img src={TwitterLogo} alt="Twitter" />
          </a>*/}
          <a className="logo2" href={emailUrl} target="_blank" rel="noopener noreferrer">
            <img src={EmailLogo} alt="E-mail" />
          </a>
          <a className="logo3" href={whatsappUrl} data-action="share/whatsapp/share">
            <img src={WhatsAppLogo} alt="Whatsapp" />
          </a>
        </div>
        <div className="w-100 related-stories">
          {/*<h6 className="w-100 text-center mb-4">OTHER STORIES</h6>
          <BlogCard blogs={relatedStories}/>*/}
        </div>
      </div>
    </div>
  )
}

export default Blog
