import React, { useState, useEffect } from 'react'
import { Nav, Navbar, Button, Dropdown } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import MobileNav from './MobileNav'
import Logo from '../../assets/images/aquient-logo-black-bluedot.png'
import headerNavs from '../../constants/headerNavs'
import { getIsActive } from '../../helpers'
import './style.scss'

const NavDropdownCustom = ({ nav }) => {
  const location = useLocation()
  const { pathname } = location
  const [show, setShow] = useState(false)
  const showDropdown = (e) => setShow(true)
  const hideDropdown = (e) => setShow(false)
  useEffect(() => {
    hideDropdown()
  }, [location])

  // const getIsActive = (str) =>  pathname.includes(str)

  const scrollToPosition = () => {
    if (getIsActive('/platforms',pathname) && !!pathname.replace('/platforms', '')) {
      var el = document.getElementById('partnerSection');
      return el.scrollIntoView({behavior: 'smooth'});
    }
    return window.scrollTo(0, 0)
  }

  const isSubnavIncludesThisNav = (nav) => {
    if (pathname === '/') return false
    if (pathname.includes(nav.href)) return true
    if (nav?.subnavs) {
      const tempNav = nav.subnavs.filter((subnav) => subnav.href.includes(pathname)) || []
      if (tempNav.length > 0) return true
    }
    return false
  }

  return (
    <Dropdown
      className={classNames({ active: isSubnavIncludesThisNav(nav) })}
      title={nav.title}
      onMouseEnter={showDropdown} 
      onMouseLeave={hideDropdown}
      onClick={showDropdown}
      show={show}
    >
      <Dropdown.Toggle
        id={`${nav.title}-dropdown`}
        as="a"
      >
        {nav.title}
      </Dropdown.Toggle>
      <Dropdown.Menu show={show}>
        {nav.subnavs?.map((subnav, j) => 
          <Dropdown.Item
            key={`sn-${subnav.key}`}
            eventKey={subnav.key}
            as={Link}
            to={subnav.href}
            onClick={() => getIsActive(subnav.href,pathname) ? scrollToPosition() : null}
            active={getIsActive(subnav.href,pathname)}
          >{subnav.title}
          </Dropdown.Item>  
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const Header = () => {
  const { pathname } = useLocation()
  // const getIsActive = (str) => pathname.includes(str)
  return (
    <Navbar className="header" fixed="top">
      <Navbar.Brand href="/">
        <img
          alt="Aquient Logo"
          src={Logo}
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Nav className="ml-auto d-none d-md-flex full-menu-button">
        {headerNavs.map((nav, i) => {
          if (nav.subnavs) return (
            <NavDropdownCustom key={i.toString()} nav={nav} />
          )
          return (
            <Nav.Item
              key={`n-${nav.key}`}
              className={classNames({ active: getIsActive(nav.href,pathname) })}
            >
              <Link to={nav.href}>{nav.title}</Link>
            </Nav.Item>
          )
        })}
        <Button
          as={Link}
          to="/contact-us"
          variant="outline-primary"
          className={classNames('contact-us-header', { active: getIsActive('/contact-us',pathname) })}
        >CONTACT US</Button>
      </Nav>

      <MobileNav />
    </Navbar>
  )
}

export default Header
