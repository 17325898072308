import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import StoryList from '../StoryList'
import { apiUrl } from '../../../apis'
import { ReactComponent as ArrowCircleRight } from '../../../assets/icons/arrow-circle-right.svg'
import { ReactComponent as ArrowCircleLeft } from '../../../assets/icons/arrow-circle-left.svg'
import './style.scss'

const Story = ({ relatedStories }) => {
  const { caseId } = useParams()
  const [ story, setStory ] = useState()
  useEffect(() => {
    fetch(`${apiUrl}/casestudy/${caseId}`, {
      method: 'GET',
    }).then(response => response.json()).then(data => {
      setStory(data)
    }).catch(err => {
      console.error('Error:', err)
    })
    window.scrollTo(0, 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId])
  if (!story) return null

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowCircleRight fill="#F0F0F0" />,
    prevArrow: <ArrowCircleLeft fill="#F0F0F0" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  const {
    title,
    subtitle,
    client,
    industry,
    technologies,
    content,
    quotetext,
    body2,
    image1,
    image2,
    image3,
  } = story;
  return (
    <div className="story">
      <h1 className="mb-5">{title.rendered}</h1>
      <p>{subtitle}</p>
      <h6 className="text-uppercase story-cit my-5">
        <Row>
          <Col sm={3}>CLIENT:</Col>
          <Col sm={9}>{client}</Col>
        </Row>
        <Row className="mt-3">
          <Col sm={3}>INDUSTRY:</Col>
          <Col sm={9}>{industry}</Col>
        </Row>
        <Row className="mt-3">
          <Col sm={3}>TECHNOLOGIES:</Col>
          <Col sm={9}>{technologies}</Col>
        </Row>
      </h6>
      <div className="mb-5" dangerouslySetInnerHTML={{ __html: content.rendered}} />
      <Slider {...settings}>
        <img className="w-100" src={image1.guid} alt={image1.post_title} />
        <img className="w-100" src={image2.guid} alt={image2.post_title} />
        <img className="w-100" src={image3.guid} alt={image3.post_title} />
      </Slider>
      <h2 className="font-normal my-5">{quotetext}</h2>
      <div className="mb-5" dangerouslySetInnerHTML={{ __html: body2 }} />
      <div className="w-100 related-stories">
        <h6 className="w-100 text-center mb-4">RELATED SUCCESS STORIES</h6>
        <StoryList stories={relatedStories}/>
      </div>
    </div>
  )
}

export default Story
