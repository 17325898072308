import React from 'react'
import '../style.scss'

const Background1 = () => {
  return (
    <div className='banner insight-bg-1'>
        <div className="content">
            <h1 className="text-center">Latest Content</h1>
            <p className="text-center">
                Bringing the latest news to you through podcasts,
                customer stories, articles and more.
            </p>
        </div>
        <div className="circle-left">
          <div className="wrapper">
            <div className="blue" />
            <div className="pink" />
            <div className="yellow" />
          </div>
        </div>
        <div className="circle-right">
          <div className="wrapper">
            <div className="pink" />
            <div className="yellow" />
            <div className="blue" />
          </div>
        </div>
    </div>
  )
}

export default Background1
