import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import mulesoftLogo from '../../../assets/images/partner-mulesoft.jpg'
import './style.scss'

const Mulesoft = () => {
  return (
    <div className="platform-mulesoft">
      <hr />
      <Container>
        {/* <Row>
          <Col>
            <h1>Salesforce</h1>
          </Col>
        </Row> */}
        <Row className="flex-column-reverse flex-xl-row">
          <Col xl={6}>
            <h1>Mulesoft</h1>
            <p>Data at rest is data that is dead. Our approach to Mulesoft is focussed on creating ideal customer experiences that leverage your data assets and equity.
			Most businesses today connect less than one third of their business systems together which is where Mulesoft comes in. It allows businesses to connect any application, data or device via API and Anypoint Platform™.
			Our approach to integration comes from business need, rather than a technology requirement. We use Mulesoft to support business users getting data they need to deliver better experiences, KPI achievement and revenue growth.
            
			</p>
          </Col>
          <Col>
            <img className="logo mb-4" src={mulesoftLogo} alt="Mulesoft Partner" />
          </Col>
        </Row>
      </Container>

      <div className="bg-blue">
        <Container className="text-center">
          <h6 className="mb-5 fc-yellow">MULESOFT PARTNER</h6>
          <h2 className="font-normal">
            Using Mulesoft, we help our clients better connect with their customers, create one-to-one customer journeys and make
            smarter, more predictive decisions from anywhere, in real-time.
          </h2>
        </Container>
      </div>

      <hr />

      <Container className="text-center">
        <h2>Meet your personal Mulesoft team</h2>
        <p className="par-1">With more than 500 years of cumulative experience, we’re a highly skilled team of
          marketing, development, strategy and consulting experts. Your brand is like no
          other, so we build custom solutions and implement Mulesoft-based strategies
          that work specifically for you.
        </p>
      </Container>

      <Container className="service-container pt-0">
        <div className="title-line">
          <hr />
          <h6 className="text-center text-uppercase"><span>Here’s what we do:</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center mt-5">
          <Col md={6}>
            <p><b>Business Automation</b></p>
            <p>Unlock data from your systems, orchestrate business processes that automate and optimise workflows, and package them as reusable services to transform your organization into a digital platform.
            </p>
            <p><b>Single View of Customer</b></p>
            <p>Enable multiple teams to act as one, collaborating around a fully integrated 360-degree view to understand and anticipate customer needs.
            </p>
            <p><b>Mobile</b></p>
            <p>Connect mobile applications to on-premises systems of record, legacy databases, and SaaS applications. Businesses can quickly design, build, and manage APIs to enable fast, easy, and governed access to mobile application, and shrink
            </p>
          </Col>
          <Col md={6}>
            <p><b>Move to the Cloud</b></p>
            <p>Achieve rapid, seamless cloud migration through APIs that connect cloud applications with on-premises systems of record, and migrate applications to the cloud without risk of service disruption
            </p>
            <p><b>Omni-Channel</b></p>
            <p>Power omnichannel strategies that result in faster time to market, improved customer satisfaction, and increased revenue.
            </p>
            <p><b>SaaS Integration</b></p>
            <p>Allow data to quickly flow where and when it’s needed – accelerating adoption and streamlining business processes.
            </p>
          </Col>
        </Row>
        <p className="know-more text-center mt-5">
          Want to know more? Book your personal consultation with one of our Mulesoft experts today to
          see how we can help your business.
        </p>
        <Button
          as={Link}
          to={{
            pathname: '/contact-us',
            state: {
              subject: 'Consultation with Mulesoft expert',
              description: 'Please contact me using the details above regarding consultation on Mulesoft.'
            },
          }}
          className="mt-5"
        >
          Book Now<i className="fas fa-chevron-right" />
        </Button>
      </Container>
    </div>
  )
}

export default Mulesoft
