import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getCategoryId,getPostsByCategory, getAllTags, getAllCategories } from '../../../actions'
import ItemLayoutA from '../../../components/Insights/ItemLayoutA'
import NewsletterBox from '../../../components/NewsletterBox'
import Pagination from '../../../components/Pagination'
import { getCategory, getDetailLink, getCategorySlug } from '../../../helpers'
import LoadingGif from '../../../assets/icons/loading.gif'

import './style.scss'

const LatestContent = () => {
  const [data, setData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [tags, setTag] = useState([]);
  const [sticky, setSticky] = useState([]);
  const [argsPage, setArgsPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const argsPerPage = 20;
  const perPage = 8;


  useEffect(() => {
    (async function() {
      setLoading(true);
      const tags = await getAllTags();
      if(tags) {
        setTag(tags);
      }

      const categories = await getAllCategories();
      setCategories([...categories])

      const id = await getCategoryId('insights')

      if (id) {
        setCategoryId(id)
        const data = await getPostsByCategory(id, argsPage, argsPerPage);

        if(data && data.length) {
       
          setData(data);
          setArgsPage(argsPage + 1)

        
        } else {
          setLoading(false);
          formatPosts();
        }
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  useEffect(() => {
    // to get all posts per page until empty
    if (argsPage > 1) {
      (async function() {
        const res = await getPostsByCategory(categoryId, argsPage, argsPerPage);
        if (res && res.length) {

         
          setData([...data, ...res])
          setArgsPage(argsPage + 1)
        }
        else {
          setLoading(false);
          formatPosts();
        }
      })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsPage])

  const formatPosts = () => {
    if (data && data.length) {
      const findSticky = data.findIndex((x) => x.sticky === 4);
      if(findSticky > -1) {
        const sticky = data.splice(findSticky, 1);
        setSticky(sticky)
      } else {
        const latest = data.splice(0, 1);
        setSticky(latest)
      }

      const dataShow = data;
      dataShow.splice(3, 0, {id: 'newsletter'})
      setFormattedData([...dataShow]);
    } else {
      setFormattedData([]);
    }
  }

  const onPageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div className="latest-content-tab">
      {loading && (
        <div className="loadingWrapper">
          <img src={LoadingGif} alt="loading" />
        </div>
      )}
      {!loading && (
        <Row>
          <Col lg={4} md={12}>
            {!!sticky.length && (
              <ItemLayoutA
                key={sticky[0].id}
                type={getCategory(getCategorySlug(sticky[0].categories, categories))}
                item={sticky[0]}
                tags={tags}
                link1={getDetailLink(getCategorySlug(sticky[0].categories, categories), sticky[0].slug, sticky[0].link)}
                className="sticky"
              />
            )}
          </Col>
          <Col lg={8} md={12}>
            <Row className="non-sticky">
              {formattedData.map((item, i) => {
                if (
                  (currentPage === 1 && i < currentPage * perPage) || 
                  (currentPage !== 1 && i < currentPage * perPage && i >= (currentPage - 1) * perPage)
                )
                {
                  if (item.id === "newsletter") return (
                    <Col lg={3} md={4} className="newsletter-wrapper">
                      <NewsletterBox />
                    </Col>
                  );
                  return (
                    <Col lg={3} md={4}>
                      <ItemLayoutA
                        key={item.id}
                        type={getCategory(getCategorySlug(item.categories, categories))}
                        item={item}
                        tags={tags}
                        link1={getDetailLink(getCategorySlug(item.categories, categories), item.slug, item.link)}
                        className="latest-content"
                      />
                    </Col>
                  );
                }
                return null
              })}
            </Row>
          </Col>
        </Row>
      )}
      {!!formattedData.length && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(formattedData.length / perPage)}
          onChange={onPageChange}
        />
      )}
    </div>
  )
}

export default LatestContent
