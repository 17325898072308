import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as Idea } from '../../../assets/icons/idea.svg'
import { ReactComponent as Research } from '../../../assets/icons/research.svg'
import { ReactComponent as Recharge } from '../../../assets/icons/recharge.svg'
import { ReactComponent as LineChart } from '../../../assets/icons/linechart.svg'
import './style.scss'

const WhatWeDoInovate = () => {
  return (
    <div className="wwd-inovate">
      <div className="bg-yellow">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <h1 className="crit-title">Innovate</h1>
              <p className="mb-5"><b>Identify customer experience opportunities that unlock new value</b></p>
            </Col>
            <Col lg={6}>
              <h5>CX Jumpstart Workshop</h5>
              <p>At the intersection of customer truth and business truth lies a mutual value exchange between
                a company and its customers. This represents opportunity. New customer ideas, validated with a
                strong business case are that opportunity.
              </p>
              <p>
                By bringing our clients together with their customers and our experts in our CX Jumpstart
                Workshop, the goal of pushing boundaries on new products, services and business models is
                realisable in a way that is more nimble and cost effective than big, costly consultancies.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>SERVICE</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col sm={6} md={4} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Research /></div>
            <p><b>Behavioural Research</b></p>
            <p>Contextual research</p>
            <p>Usability testing</p>
            <p>Market segmentation</p>
            <p>Quantitative research</p>
            <p>User personas &amp; scenarios</p>
          </Col>
          <Col sm={6} md={4} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Idea /></div>
            <p><b>Ideation &amp; Innovation</b></p>
            <p>Growth opportunities</p>
            <p>New products &amp; services</p>
            <p>In-market pilots</p>
            <p>Opportunity identification</p>
            <p>Concepting & prototyping</p>
          </Col>
          <Col sm={6} md={4} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Recharge /></div>
            <p><b>Rapid Prototyping</b></p>
            <p>Agile sprint based workflow</p>
            <p>Optimisation prioritisation</p>
          </Col>
          <Col sm={6} md={4} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><LineChart /></div>
            <p><b>Business Consulting</b></p>
            <p>Future casting</p>
            <p>Customer experience strategy</p>
            <p>Growth opportunities</p>
            <p>Operational excellence</p>
            <p>Digital transformation</p>
            <p>Business planning &amp; modelling</p>
            <p>Technology strategy</p>
            <p>Business innovation</p>
          </Col>
        </Row>
      </Container>
      <div className="bg-blue">
        <Container>
          <h2 className="text-md-center mb-5">Positioned for Success</h2>
          <Row>
            <Col lg={6}>
              <h2 className="font-normal mb-4">
                We’re uniquely positioned to help our customers succeed because, as marketers ourselves, we
                understand the challenges marketers face and the outcomes they want to see.
              </h2>
              <p>
                Our stand-out team of service, marketing, development, strategy and consulting experts
                understand the uniqueness of each customer’s brand and provide <b>custom solutions that
                deliver measurable outcomes and results.</b>
              </p>
            </Col>
            <Col lg={6}>
              <p><b>
                Each team pod is built to align expertise with the
                customer's needs. Engagements include a
                combination of the following:
              </b></p>
              <p><b>Project Manager</b><br />Responsible for project plan development, adherence to budget and timelines and project scope control.</p>
              <p><b>CX Consultant</b><br />Drives the development of the digital marketing strategy.</p>
              <p><b>Solution Architect</b><br />Provides technical guidance to enhance efforts and builds, tests and delivers technical solutions.</p>
              <p><b>Platform Specialist</b><br />Performs advanced training, coding and segmentation.</p>
              <p><b>QA Consultant</b><br/> Drives quality in the development process and ensures outcomes match designs.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default WhatWeDoInovate
