import React, { useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import WhatWeDoTabBar from './WhatWeDoTabBar'
import Innovate from './Innovate'
import Transform from './Transform'
import Activate from './Activate'
import MainLayout from '../../layouts/MainLayout.js'
import img1 from '../../assets/images/whatwedo1.png'
import './style.scss'

const WhatWeDoInnovate = () => {
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  useEffect(() => {
    if (!!pathname.replace('/what-we-do', '')) {
      var el = document.getElementById('critical-part');
      el.scrollIntoView({behavior: 'smooth'});
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname])
  return (
    <div className="what-we-do">
      <MainLayout>
      <Helmet>
          <title>Aquient | What We Do</title>
          <meta name="description" content="Creating a unified brand experience" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/what-we-do" />
          <meta name="og:title" content="Aquient | What We Do"/>
          <meta name="og:description" content="Creating a unified brand experience" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | What We Do" />
          <meta name="twitter:description" content="Creating a unified brand experience" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="mb-5">
              <h6 className="text-uppercase">
                WHAT WE DO
              </h6>
              <h1>
                Creating a unified brand experience
              </h1>
              <p>
                Lean, interdisciplinary teams working in close collaboration with our clients, helping them to
                solve their customers’ biggest problems. Our customer-centric, data-driven approach focuses on
                creating a seamless experience across all digital touchpoints. A unified brand experience results
                in a much more consistent, impactful and valuable relationship with the user.
              </p>
            </Col>
            <Col lg={6}>
              <img className="w-100" src={img1} alt="what we do 1"></img>
            </Col>
          </Row>
        </Container>

        <div className="critical-section">
          <Container>
            <Row>
              <Col>
                <h2 className="critical-intro">Aquient connects the three critical parts needed today to drive brand growth:</h2>
              </Col>
            </Row>
            <div id="critical-part"/>
          </Container>
          <WhatWeDoTabBar />
          <Switch>
            <Route exact path={path}><Innovate /></Route>
            <Route path={`/what-we-do/innovate`}><Innovate /></Route>
            <Route path={`/what-we-do/transform`}><Transform /></Route>
            <Route path={`/what-we-do/activate`}><Activate /></Route>
          </Switch>
        </div>
      </MainLayout>
    </div>
  )
}

export default WhatWeDoInnovate
