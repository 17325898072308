import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Story from './Story'
import StoryList from './StoryList'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import ScrollToTopOnMount from '../../routers/ScrollToTopOnMount.js'
import { apiUrl } from '../../apis'
import './style.scss'

const CaseStudies = () => {
  let { path } = useRouteMatch();
  const [ cases, setCases ] = useState()
  useEffect(() => {
    fetch(`${apiUrl}/casestudy/`, {
      method: 'GET',
    }).then(response => response.json()).then(data => {
      if (!data?.data?.status === 404 || data.length > 0) setCases(data)
    }).catch(err => {
      console.error('Error:', err)
    })
  }, [])
  return (
    <div className="case-studies-page">
      <ScrollToTopOnMount />
      <MainLayout>
      <Helmet>
          <title>Aquient | Case Studies</title>
          <meta name="description" content="Aquient | Case Studies" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/case-studies" />
          <meta name="og:title" content="Aquient | Case Studies"/>
          <meta name="og:description" content="Aquient | Case Studies" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Case Studies" />
          <meta name="twitter:description" content="Aquient | Case Studies" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container>
          <h6 className="text-uppercase">Case Studies</h6>
          <Switch>
            <Route exact path={path}>
              <StoryList stories={cases}/>
            </Route>
            <Route path={`/case-studies/:caseId`}>
              <Story relatedStories={cases?.filter((c, i) => i <3)} />
            </Route>
          </Switch>
        </Container>
      </MainLayout>
    </div>
  )
}

export default CaseStudies
