import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import dateFormat from 'dateformat'
import { getSinglePostBySlug, getAllTags, getMedia } from '../../../../actions'
import MainLayout from '../../../../layouts/MainLayout.js/index.js'
import ScrollToTopOnMount from '../../../../routers/ScrollToTopOnMount.js'
import TagList from '../../../Blog/TagList'
import YouMightLike from '../../../../components/YouMightLike'
import NewsletterBox from '../../../../components/NewsletterBox'
import { ReactComponent as ArticleIcon } from '../../../../assets/icons/insights/article.svg'
import FacebookLogo from '../../../../assets/icons/Facebook.svg'
import LinkedinLogo from '../../../../assets/icons/Linkedin.svg'
//import TwitterLogo from '../../../../assets/icons/Twitter.svg'
import WhatsAppLogo from '../../../../assets/icons/whatsapp.svg'
import EmailLogo from '../../../../assets/icons/E-mail.svg'
import './style.scss'

const AquientNewsSingle = () => {
  const [data, setData] = useState(null);
  const [ tags, setTag] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [thumbImg, setThumbImg] = useState('')
  // const [author, setAuthor] = useState('');
  const { slug } = useParams()

  const fbUrl = `https://www.facebook.com/dialog/share?app_id=544889153158229&display=popup&href=${encodeURIComponent(window.location.href)}`
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`
  //const twitterUrl = `http://www.twitter.com/share?url=${encodeURIComponent(window.location.href)}` 
  const emailUrl = `mailto:?subject=Aquient Insights&body=Check this out from the Aquient website: ${encodeURIComponent(window.location.href)}`
  const whatsappUrl = `https://wa.me?text=${encodeURIComponent(window.location.href)}`

  useEffect(() => {
    (async function() {
      const tags = await getAllTags();
      const data = await getSinglePostBySlug(slug);
      if (data.length) {
        setData(data[0])
        // const author = await getAuthorByLink(data[0]._links.author[0].href)
        // if (author) {
        //   setAuthor(author);
        // }
      }
      if(tags) {
        setTag(tags);
      }
    })()
  }, [slug])

  useEffect(() => {
    (async function() {
      if (data) {
        let size = 'large'
        if (window.innerWidth <= 480) {
          size = 'small'
        }
        const imgLink = await getMedia(data.featured_media, size);
        setThumbImg(imgLink);
      }
    })()
  }, [data])

  const blogTag = [];
  for (let i = 0; i < tags.length; i++) {
    const currentTag = tags[i];
    if(data.tags.includes(currentTag.id)) {
      blogTag.push(currentTag);
    }
  }

  if (data === null) return null;
  return (
    <div className="single-post aquient-news-single">
      <ScrollToTopOnMount />
      <MainLayout>
        <Container fluid className="content">
          {/* <h3>Article</h3>
          <span className="crumb">
            <Link to="/insights">Insight</Link> - <Link to="/insights/articles">Articles</Link> - {data.title.rendered}
          </span>
          <div className="separator" />
          <h3>{data.title.rendered}</h3>
          <span className="publish-date">Publish Date: {dateFormat(data.date, "dd/mm/yyyy")}</span>
          <span className="author">Written By: {author && author.name}</span> */}
          <div className='single-banner article-banner'>
            {/* <div className='default-icon'>
              <ArticleIcon fill="#fff" />
              <span>Article</span>
            </div> */}
            {thumbImg !== '' && (
              <img className="media-img" src={thumbImg} alt="" />
            )}
            {thumbImg === '' && (
              <div className='default-icon'>
                <ArticleIcon fill="#fff" />
                <span>Article</span>
              </div>
            )}
          </div>
        </Container>
        <Container className="main-content">
          <h1 className="title" dangerouslySetInnerHTML={{ __html: data.title.rendered }} />
          <div className="text-left mb-5" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
          <div>
            <strong>{dateFormat(data.date, "mmmm dS, yyyy")}</strong>
            <div><TagList tagCollection={blogTag} /></div>
            <div className="mt-4">
              <a className="logo" href={fbUrl} target="_blank" rel="noopener noreferrer">
                <img src={FacebookLogo} alt="Facebook" />
              </a>
              <a className="logo" href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                <img src={LinkedinLogo} alt="LinkedIn" />
              </a>
              {/*<a className="logo" href={twitterUrl} target="_blank" rel="noopener noreferrer">
                <img src={TwitterLogo} alt="Twitter" />
              </a>*/}
              <a className="logo2" href={emailUrl} target="_blank" rel="noopener noreferrer">
                <img src={EmailLogo} alt="E-mail" />
              </a>
              <a className="logo3" href={whatsappUrl} data-action="share/whatsapp/share">
                <img src={WhatsAppLogo} alt="Whatsapp" />
              </a>
            </div>
          </div>
        </Container>
        <Container fluid>
          <div className="you-might-like-section">
            <YouMightLike />
          </div>
          <div className="newsletter-section">
            <NewsletterBox type="B" />
          </div>
        </Container>
      </MainLayout>
    </div>
  )
}

export default AquientNewsSingle
