export const getCategory = (category_slug) => {
  
  if (category_slug === 'articles')
    return 'article'
  if (category_slug === 'customer-stories')
    return 'customer-story'
  if (category_slug === 'e-books')
    return 'e-book'
  if (category_slug === 'marketing-mentors')
    return 'podcast'
  if (category_slug === 'press-release')
    return 'press'
  if (category_slug === 'videos')
    return 'video'
  if (category_slug === 'webinar') {
    return 'webinar'
  }
  // if (category_slug === 'webinars') {  used for development branch
  //   return 'webinar'
  // } 
  return 'article'
}

export const getDetailLink = (category_slug, slug, link) => {

  
  if (category_slug === 'articles')
    return `/insights/articles/${slug}`
  if (category_slug === 'customer-stories')
    return `/insights/customer-stories/${slug}`
  if (category_slug === 'e-books')
    return `/insights/e-books/${slug}`
  if (category_slug === 'marketing-mentors')
    return `/insights/marketing-mentors/${slug}`
  if (category_slug === 'press-release')
    return `/insights/press-release/${slug}`
  if (category_slug === 'videos')
    return `/insights/videos/${slug}`
  if (category_slug === 'webinar') 
    return `/insights/webinars/${slug}`
  // if (category_slug === 'webinars')  // used for development branch
  // return `/insights/webinars/${slug}`
  return `/insights/articles/${slug}`
}

export const getCategorySlug = (category_id, allCategories) => {
  if (category_id && category_id.length) {
    // eslint-disable-next-line
    category_id.sort().reverse()
    const slug = allCategories.find((obj) => obj.id === category_id[0])?.slug;
    if (slug) {
      return slug
    }
  }
  return ''
}


export const getIsActive = (str,pathname) =>  {
    
  if ((pathname.includes('/insights') && pathname.replace('/insights','') !== '') && str.includes('/insights')  ) {

    if (str.replace('/insights','') !== '') {
      
      return pathname.includes(str.replace('/insights',''))
    }
    return false
  }
 
  return pathname.includes(str)
}


export const comingSoon = (type,item) => {
  
  if (type === 'webinar' ) {
   
    if (item.split('|')[1]) {
      return item.split('|')[1].trim() === 'Coming Soon' 
    }
    return false
  }
}

export const getRegistrationLink = (item) => {
  let webinarTitle = item.title.rendered.split('|')[0]

  const links = [
    {title: 'Webinar 1: Einstein for Marketing Cloud, Datorama: More Insights &#038; Less Reporting',
    registrationLink: 'https://us06web.zoom.us/webinar/register/7316769438143/WN_BjHpZtHXTHeXALxTLGV7tQ'},
    {title: 'Webinar 2. Salesforce CDP (Genie)',
    registrationLink: 'https://us06web.zoom.us/webinar/register/WN_MRUp-7SiSPeE3FtdC8vAGg'}
    ,{title: 'Webinar 3. Marketing Cloud Personalisation: Use Cases and Case Study',
    registrationLink: 'https://us06web.zoom.us/webinar/register/WN_iaJgO19uSW-ZZKy9Z3oK4A'}
  ]

  const webinarLink = links.find(linkItem => linkItem.title === webinarTitle)
  if (webinarLink) {
    return webinarLink.registrationLink
  }
  return ''
}