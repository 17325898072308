import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import img1 from '../../../assets/images/gcloud1.png'
import './style.scss'

const GoogleCloud = () => {
  return (
    <div className="platform-gcloud">
      <div className="w-100 text-center">
        <img className="img-1" src={img1} alt="" />
      </div>
      <Container>
        <h1>Google Cloud</h1>
        <Row>
          <Col lg={6}>
            <p>Leave costly overhead behind and reach your goals faster.</p>
            <p><b>Google Cloud Solutions</b></p>
            <ul>
              <li>App Engine</li>
              <li>Cloud Pub/Sub</li>
              <li>Data Studio</li>
              <li>BigQuery</li>
              <li>Data Analytics</li>
              <li>Dataflow</li>
              <li>Cloud Storage</li>
              <li>Infrastructure</li>
            </ul>
          </Col>
        </Row>
      </Container>

      <div className="bg-dodgerblue">
        <Container className="text-center">
          <h2 className="font-normal">Our capabilities are built on the most comprehensive cloud
            platform, optimized for machine learning with high-performance compute, and no
            compromises on security and analytics.
          </h2>
        </Container>
      </div>

      <Container>
        <h1 className="w-100 text-center mb-5">What we do on GCP</h1>
        <Row>
          <Col lg={6}>
            <p><b>Data &amp; Analytics</b></p>
            <p>Ingest event data any time, anywhere, and use the same NoSQL Big Data database
              that powers Google Search and Gmail
            </p>
            <p><b>Machine Learning &amp; AI</b></p>
            <p>Get real ROI from Google Cloud’s modern machine learning services and pre-trained
              and tailored models
            </p>
            <p><b>Data Infrastructure</b></p>
            <p>We’ll help you move to Google’s serverless, highly scalable data warehouse, so
              you can focus on getting insights versus managing infrastructure
            </p>
          </Col>
          <Col lg={6}>
            <p><b>Cloud transformation</b></p>
            <p>We’ll help analyse your business needs to find a cloud storage solution that works
              for you now, and as your team grows
            </p>
            <p><b>Application Development</b></p>
            <p>Deploy, manage and scale containerised applications and build a more modern web and
              mobile experience
            </p>
            <p><b>Data visualisation</b></p>
            <p>Turn your data into informative dashboards and reports that are easy to read, easy
              to share and fully customisable
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <h2 className="w-100 text-center mb-5">Machine Learning on Google Cloud</h2>
        <Row>
          <Col lg={6}>
            <h2 className="font-normal">We will help you to choose from pre-trained AI services for
              vision, language, recommendations and forecasting to quickly build, train and deploy
              machine learning models at scale; or build custom models with support for all the
              popular open-source frameworks.
            </h2>
          </Col>
          <Col lg={6}>
            <p>Our capabilities are built on the most comprehensive cloud platform, optimized for
              machine learning with high-performance compute, and no compromises on security and
              analytics. Google pre-trained AI Services provide ready-made intelligence for your
              applications and workflows. AI Services easily integrate with your applications to
              address common use cases such as personalized recommendations, modernizing your
              contact center, improving safety and security, and increasing customer engagement.
            </p>
            <p>Because we use the same deep learning technology that powers Google, you get
              quality and accuracy from continuously-learning APIs. And best of all, AI Services
              on Google don’t require machine learning experience.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="service-container pt-0">
        <div className="title-line">
          <hr />
          <h6 className="text-center text-uppercase"><span>How we do it</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center mt-5">
          <Col md={6}>
            <p><b>Recommendations</b></p>
            <p>Personalize experiences for your customers with the same recommendation technology
              used at Amazon.com.
            </p>
            <p><b>Forecasting</b></p>
            <p>Build accurate forecasting models based on the same machine learning forecasting
              technology used by Amazon.com.
            </p>
            <p><b>Image and Video Analysis</b></p>
            <p>Add image and video analysis to your applications to catalog assets, automate media
              workflows, and extract meaning.
            </p>
          </Col>
          <Col md={6}>
            <p><b>Advanced Text Analytics</b></p>
            <p>Use natural language processing to extract insights and relationships from unstructured text.
            </p>
            <p><b>Document Analysis</b></p>
            <p>Automatically extract text and data from millions of documents in just hours, reducing manual efforts.
            </p>
            <p><b>Deliverables</b></p>
            <p>Deliverables Pre-trained or custom model deployed on TensorFlow, or other popular frameworks to
              experiment with and customize machine learning algorithms.
            </p>
          </Col>
        </Row>
      </Container>

      <div className="bg-dodgerblue">
        <Container className="text-center">
          <h2 className="font-normal">Want to build your ML pipeline on GCP? We’ve got your back. Our industry expert
            team of big data engineers are the time-conscious, cost-conscious, and customer-first solution to
            your machine learning needs.
          </h2>
        </Container>
      </div>

      <Container className="service-container">
        <h2 className="w-100 text-center mb-5">Data Lakes and Analytics on Google Cloud</h2>
        <Row className="flex-wrap justify-content-center mb-5">
          <Col md={6}>
            <h2 className="font-normal">Google Cloud Platform delivers an integrated suite of services that
              provide everything needed to quickly and easily build and manage a data lake for analytics.
            </h2>
          </Col>
          <Col md={6}>
            <p>We will help you to design and build Google Cloud Platform-powered data lakes that can
              handle the scale, agility, and flexibility required to combine different types of data and
              analytics approaches to gain deeper insights, in ways that traditional data silos and data
              warehouses cannot.
            </p>
            <p>The first step to building data lakes on GCP is to move data to the cloud. The physical
              limitations of bandwidth and transfer speeds restrict the ability to move data without
              major disruption, high costs, and time. To make data transfer easy and flexible, GCP
              provides the widest range of options to transfer data to the cloud.
            </p>
          </Col>
        </Row>
        <p className="know-more text-center mt-5">
          Want to know more? Book your personal consultation with one of our Google Cloud experts today to
          see how we can help your business.
        </p>
        <Button
          as={Link}
          to={{
            pathname: '/contact-us',
            state: {
              subject: 'Consultation with Google Cloud expert',
              description: 'Please contact me using the details above regarding consultation on Google Cloud.'
            },
          }}
          className="mt-5"
        >
          Book Now<i className="fas fa-chevron-right" />
        </Button>
      </Container>
    </div>
  )
}

export default GoogleCloud
