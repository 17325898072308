import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
//import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import classNames from 'classnames'
import ScrollToTopOnMount from '../../routers/ScrollToTopOnMount.js'
import MainLayout from '../../layouts/MainLayout.js'
import './style.scss'
import { useLocation, Route } from 'react-router-dom'
import Switch from 'react-bootstrap/esm/Switch'


const Input = ({ id, name = "", label = "", as = "input", maxLength = 50000, type, value, onChange, required = false, error = "" }) => {
  return (
    <Form.Group controlId={id} className={classNames({ 'd-none': type === 'hidden' })}>
      <Form.Label>{label || id} {required && <sup>*</sup>}</Form.Label>
      <Form.Control
        required={required}
        className={classNames({ error: error })}
        as={as}
        name={name || id}
        type={type || 'text'}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">
        {label} is required!
      </Form.Control.Feedback>
    </Form.Group>
  )
}


const ContactUs = () => {
  const { pathname, state } = useLocation()
  // const [ts, setTs] = useState(new Date().getTime())
  // const [token, setToken] = useState('something')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState(state?.subject || '')
  const [description, setDescription] = useState(state?.description || '')
  const [validated, setValidated] = useState(false);



  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const response = document.getElementById("g-recaptcha-response").value;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      
    }
    if (response == null || response === "" || response.value.trim() === "") {
      document.getElementById("g-recaptcha-error").innerHTML = "Verify you are not a robot.";
      event.preventDefault();
      event.stopPropagation();
    }
    

    setValidated(true);
  };

  // const handleSubmit = () => {
  //   let data = new URLSearchParams();
  //   data.append('captcha_settings', `{"keyname":"devaquientco","fallback":"true","orgId":"00D6F000002VNmF","ts":"${JSON.stringify(ts)}"`);
  //   data.append('orgid', '00D6F000002VNmF');
  //   data.append('retURL', 'http://dev.aquient.co/contact-us/thankyou');
  //   data.append('debug', '1');
  //   data.append('debugEmail', 'ben.pinkerton@aquient.asia');
  //   data.append('name', name);
  //   data.append('email', email);
  //   data.append('phone', phone);
  //   data.append('subject', subject);
  //   data.append('description', description);
  //   data.append('g-recaptcha-response', token);
  //   data.append('submit', 'Submit');
  //   fetch(`https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8`, {
  //     method: 'POST',
  //     body: data,
  //   }).then(res => {
  //     if (res.status === 200) setSuccess(true);
  //   }).catch(err => {
  //     console.error('Error:', err)
  //   })
  // }

  // const handleVerify = (verToken) => {
  //   if (!token) {
  //     setToken(verToken)
  //     setTs(new Date().getTime())
  //   }
  // }

  // const validateEmail = (mail) => {
  //   if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) return true
  //   return false
  // }

  // const getValidated = () => {
  //   let tempErrors = {}
  //   if (!name) tempErrors = {...tempErrors, name: 'Must fill'}
  //   if (!email) tempErrors = {...tempErrors, email: 'Must fill'}
  //   else if (!validateEmail(email)) tempErrors = {...tempErrors, email: 'Email address is invalid!'}
  //   if (!phone) tempErrors = {...tempErrors, phone: 'Must fill'}
  //   if (!subject) tempErrors = {...tempErrors, subject: 'Must fill'}
  //   if (!description) tempErrors = {...tempErrors, description: 'Must fill'}
  //   console.log(tempErrors)
  //   setErrors(tempErrors)
  //   return Object.keys(tempErrors).length > 0
  // }

  useEffect(() => {
    const timestamp = () => {
      const response = document.getElementById("g-recaptcha-response")
      if (response == null || response.value.trim() === "") {
        if (document.getElementsByName("captcha_settings")[0]) {
          const elems = JSON.parse(document.getElementsByName("captcha_settings")[0]?.value)
          elems["ts"] = JSON.stringify(new Date().getTime())
          document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems)
        }
      }
    }
    // setInterval(timestamp, 500);
    setTimeout(timestamp, 500);
    if(!window.location.hash) {
      window.location += '#';
      window.location.reload();
    }
  }, [])

  return (
    <div className="contact-us-page">
      <ScrollToTopOnMount />
      <MainLayout>
      <Helmet>
          <title>Aquient | Contact Us</title>
          <meta name="description" content="If you have an enquiry or are a prospective business looking at working with us, feel free to reach out!" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/contact-us" />
          <meta name="og:title" content="Aquient | Contact Us"/>
          <meta name="og:description" content="If you have an enquiry or are a prospective business looking at working with us, feel free to reach out!" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Contact Us" />
          <meta name="twitter:description" content="If you have an enquiry or are a prospective business looking at working with us, feel free to reach out!" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container className="text-center intro">
          <h1>Get in touch</h1>
          <h2 className="font-normal">
            If you have an enquiry or are a prospective business looking at working with us, feel free to reach
            out! {pathname.includes('thankyou') && 'We’ll get back to you ASAP, usually within 3 working days.'}
          </h2>
        </Container>
        <Switch>
          <Route exact path="/contact-us">
            <Container>
              {/* <GoogleReCaptchaProvider reCaptchaKey="6LdiNN8ZAAAAAGQZL1szSldAKkjOwzy3rlR7TYLO"> */}           
               <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST"
                >
                  <input type="hidden" name='captcha_settings' value='{"keyname":"prodKeyAquientCo","fallback":"true","orgId":"00D6F000002VNmF","ts":""}' />
                  <input type="hidden" name="orgid" value="00D6F000002VNmF" />
                  <input type="hidden" name="retURL" value="https://aquient.asia/contact-us/thankyou" />
                  {/* <input type="hidden" name="debug" value="1" />
                  <input type="hidden" name="debugEmail" value="ben.pinkerton@aquient.asia" />*/}
                  <Input
                    required
                    id="name"
                    label="Contact Name"
                    name="name"
                    maxLength="80"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <Input
                    required
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    maxLength="80"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    />
                  <Input
                    id="phone"
                    label="Phone"
                    name="phone"
                    type="phone"
                    maxLength="40" 
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                  <Input
                    required
                    id="subject"
                    label="Subject"
                    name="subject"
                    maxLength="80"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                  />
                  <Input
                    required
                    id="description"
                    label="Description"
                    name="description"
                    as="textarea"
                    maxLength="50000"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />

                  {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
                  <div className="g-recaptcha" data-sitekey="6Lcl3ToaAAAAAGz5dsQkxGEspmiFqVmS6IptGv1m" render="automatic"></div><br />
                  <p className="text-danger text-center" id="g-recaptcha-error"/>
                  <div className="text-center">
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              {/* </GoogleReCaptchaProvider> */}
            </Container>
          </Route>
          <Route path="/contact-us/thankyou">
            <Container className="text-center success-container">
              <div className="check-img"><i className="fas fa-check-circle" /></div>
              <h2>Submission successful! You’ll hear from us soon.</h2>
            </Container>
          </Route>
        </Switch>
        <div className="bg-yellow">
          <Container>
            <h1 className="text-center mb-5">Regional Offices</h1>
            <Row className="row-1">
              <Col md={6} lg={4} className="mb-5 text-center">
                <h2 className="mb-4">Singapore</h2>
                <p>5 Shenton Way,<br />UIC Building #10-01,<br />Singapore 068808</p>
                <a href="mailto:sg@aquient.asia" target="_blank" rel="noopener noreferrer">sg@aquient.asia</a>
              </Col>
              <Col md={6} lg={4} className="mb-5 text-center">
                <h2 className="mb-4">Bangkok</h2>
                <p>4/1 K Building<br />22 Sukhumvit 35, Sukhumvit Road<br />Klongton-Nua, Wattana, Bangkok<br />Thailand 10110</p>
                <a href="mailto:th@aquient.asia" target="_blank" rel="noopener noreferrer">th@aquient.asia</a>
              </Col>
              <Col md={6} lg={4} className="mb-5 text-center">
                <h2 className="mb-4">Manila</h2>
                <p>27F Esplanade @ KMC<br />The Podium West Tower<br />Dña. Julia Vargas Ave.<br />Mandaluyong City<br />Philippines 1555</p>
                <a href="mailto:ph@aquient.asia" target="_blank" rel="noopener noreferrer">ph@aquient.asia</a>
              </Col>
              <Col md={6} lg={6} className="mb-5 text-center">
                <h2 className="mb-4">Kuala Lumpur</h2>
                <p>15/F, Blk B, Plaza Zurich<br />12 Gelenggang, Bkt D&apos;sara,<br />50490 KL, Malaysia</p>
                <a href="mailto:contact@aquient.asia" target="_blank" rel="noopener noreferrer">contact@aquient.asia</a>
              </Col>
                            
            </Row>
          </Container>
        </div>
      </MainLayout>
    </div>
  )
}

export default ContactUs
