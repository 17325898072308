import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../../../assets/images/cx-design.svg'
import { ReactComponent as Business } from '../../../assets/icons/business-report.svg'
import { ReactComponent as Conversion } from '../../../assets/icons/conversion.svg'
import { ReactComponent as Media } from '../../../assets/icons/media-monitor.svg'
import { ReactComponent as SocMed } from '../../../assets/icons/social-media.svg'
import { ReactComponent as Support } from '../../../assets/icons/support.svg'
import { ReactComponent as UserExp } from '../../../assets/icons/user-experience.svg'
import { ReactComponent as Optimisation } from '../../../assets/icons/ux-optimisation.svg'
import './style.scss'

const DataScience = () => {
  return (
    <div id="cxDesign" className="cx-design">
      <Container>
        <Row className="align-items-end flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <h6 className="text-uppercase">PRACTICE AREAS</h6>
            <h1>CX Design</h1>
            <p className="text-justify">
              Utilising industry best practices and a user-first approach to drive customer values and
              deliver custom-built experiences.
            </p>
          </Col>
          <Col lg={6} className="mb-5 mb-lg-0">
              <img src={img1} alt='' />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={6}>
            <h2 className="font-normal">
              Your customers are more demanding than ever before. The experiences you provide them with are
              more important than the products you sell them
            </h2>
          </Col>
          <Col lg={6}>
            <p>
              Happy customers drive growth. They transact more often, spend more, cost less to serve, and
              promote your organisation to others. Conversely, dissatisfied customers spend less, cost more
              to handle complaints, and actively discourage others from doing business with you.
            </p>
            <p>
              Truly understanding your customer, and empowering your teams with this information, allows you
              to take the actions needed to delight customers, build loyalty, and focus on the things that
              matter to help your business grow.
            </p>
            <p>
              With a blended team of digital strategists, UI/UX designers, design thinking practitioners and
              digital marketers, Aquient’s CX practice can help you truly deliver the right experience to the
              right customer via the right channel at the right time.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>SERVICE</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><UserExp /></div>
            <p className="font-normal text-center"><b>Personalised User Experience</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Business /></div>
            <p className="font-normal text-center"><b>Reporting</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Support /></div>
            <p className="font-normal text-center"><b>Customer Service &amp; Retention</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Optimisation /></div>
            <p className="font-normal text-center"><b>UX/UI Optimisation</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><SocMed /></div>
            <p className="font-normal text-center"><b>Media Attribution</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Media /></div>
            <p className="font-normal text-center"><b>Omni-Channel Marketing</b></p>
          </Col>
          <Col xs={6} md={4} lg={3} className="mt-5">
            <div className="text-center mb-4"><Conversion /></div>
            <p className="font-normal text-center"><b>Lead Nurturing</b></p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DataScience
