import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const TagList = ({tagCollection}) => {

  return(
    <div className="tag-list">
        {tagCollection.length > 0 ? <strong>Tags: </strong> : ""}
        {tagCollection?.map((tag, index) => (
        <strong key={index}>
            <Link to={`/insights/tags?id=${tag.id}&name=${tag.name}`} style={{ color: 'blue' }}>
                {tag.name}
            </Link>&nbsp;&nbsp; 
        </strong>
        ))}
    </div>
  )
}

export default TagList