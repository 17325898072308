import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import dateFormat from 'dateformat'
import TagList2 from '../../TagList'
import { getMedia } from '../../../actions'
import {comingSoon} from '../../../helpers/index'
import {getRegistrationLink} from '../../../helpers/index'
import { ReactComponent as EbookIcon } from '../../../assets/icons/insights/ebook.svg'
import { ReactComponent as PressReleaseIcon } from '../../../assets/icons/insights/pressrelease.svg'
import { ReactComponent as ArticleIcon } from '../../../assets/icons/insights/article.svg'
import { ReactComponent as WebinarIcon } from '../../../assets/icons/insights/webinar.svg'
import { ReactComponent as PodcastIcon } from '../../../assets/icons/insights/podcast.svg'
import { ReactComponent as VideoIcon } from '../../../assets/icons/insights/video.svg'
import './style.scss'

// function findPDFLink(text) {
//   var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
//   let pdflink = '';
//   text.replace(urlRegex, function(url) {
//     pdflink = url;
//   });
//   return pdflink
// }

const ItemLayoutA = ({type, item, tags, isFirst, className, link1 = '/'}) => {
  const [thumbImg, setThumbImg] = useState('')
  const history = useHistory()

  
  useEffect(() => {
    (async function() {
      if (item.id) {
        let size = 'medium'
        if (window.innerWidth <= 480) {
          size = 'small'
        }
        let imgLink = await getMedia(item.featured_media, size);
        if (imgLink === undefined) {
          imgLink = ''
        }
        setThumbImg(imgLink);
      }
    })()
  }, [item])

  const gotoDetail = () => {

      if (!comingSoon(type, item.title.rendered)) {
        history.push(link1)

        scrollTopPage()
      }
  }


 



  const scrollTopPage = () => {
    const element = document.getElementById('root')
    const offset = 0
    const elementPosition = element.offsetTop
    const offsetPosition = elementPosition - offset
    window.scrollTo({
        top: offsetPosition,
        // behavior: "smooth"
    })
  }

  // const downloadPDF = () => {
  //   const url = findPDFLink(item.content.rendered)

  //   fetch(url, {
  //     method: 'GET',
  //     responseType: 'blob'
  //   })
  //   .then((response) => response.blob())
  //   .then((blob) => {
  //     // Create blob link to download
  //     const url = window.URL.createObjectURL(
  //       new Blob([blob]),
  //     );
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute(
  //       'download',
  //       `${item.slug}.pdf`,
  //     );

  //     // Append to html link element page
  //     document.body.appendChild(link);

  //     // Start download
  //     link.click();

  //     // Clean up and remove the link
  //     link.parentNode.removeChild(link);
  //   });
  // }

  const blogTag = [];
  for (let i = 0; i < tags.length; i++) {
    const currentTag = tags[i];
    if(item.tags.includes(currentTag.id)) {
      blogTag.push(currentTag);
    }
  }
  
  return (
    
    <Card className={classNames('item-wrapper-A', className)}>
      <div
        className={classNames('thumb-wrapper', { haveImg: thumbImg !== '' })}
        onClick={gotoDetail}
      >
        {thumbImg !== '' && (
          <img className="media-img" src={thumbImg} alt="" />
        )}
        {type === 'video' && thumbImg === '' && (
          <div className="video-thumb">
            <VideoIcon fill="#212529" />
            <span>Videos</span>
          </div>
        )}
        {type === 'e-book' && thumbImg === '' && (
          <div className="e-book-thumb">
            <EbookIcon fill="#fff" />
            <span>Ebooks</span>
          </div>
        )}
        {type === 'press' && thumbImg === '' && (
          <div className="press-r-thumb">
            <PressReleaseIcon fill="#fff" />
            <span>Press Release</span>
          </div>
        )}
        {(type === 'article' || type === 'customer-story') && thumbImg === '' && (
          <div className="article">
            <ArticleIcon fill="#fff" />
            <span>Article</span>
          </div>
        )}
        {type === 'podcast' && thumbImg === '' && (
          <div className="podcast">
            <PodcastIcon fill="#fff" />
            <span>Podcast</span>
          </div>
        )}
        {type === 'webinar' && thumbImg === '' && (
          <div className="webinar">
            <WebinarIcon fill="#fff" />
            <span>Webinar</span>
          </div>
        )}
       
      </div>
      <div className="content-wrapper">
        <span className="date">
          {comingSoon(type,item.title.rendered) ? 
          <strong>Coming Soon</strong>: 
          <strong>{dateFormat(item.date, "mmmm dS, yyyy")}</strong>}
        </span>
        {comingSoon(type, item.title.rendered) ? 
          <h3 dangerouslySetInnerHTML={{ __html: item.title.rendered.split('|')[0] }}></h3>
         :
        <Link to={link1} style={{ textDecoration: 'none' }} onClick={scrollTopPage}>
        <h3 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h3>
      </Link>}

        <div className="text-desc" dangerouslySetInnerHTML={{ __html: item.excerpt.rendered.replace('[&hellip;]', `<a style="color:blue" href="${link1}">[read more]</a>`)}}/>
      </div>
      <div className="tags-wrapper">
        <TagList2 tagCollection={blogTag} type={type} /> 
      </div>
      <div className="actions-wrapper">
        {type === 'video' && !(className === 'latest-content' || className === 'sticky') && (
          <button className="watch-video" onClick={gotoDetail}>
            Watch Full Video
          </button>
        )}
        {type === 'video' && (className === 'latest-content' || className === 'sticky') && (
          <button className="watch-video" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'e-book' && (
          <button className="open-pdf" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {/* {type === 'e-book' && className === 'latest-content' && findPDFLink(item.content.rendered) === '' && (
          <button className="open-pdf" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'e-book' && className === 'latest-content' && findPDFLink(item.content.rendered) !== '' && (
          <a href={findPDFLink(item.content.rendered)} target="_blank" rel="noopener noreferrer">
            <button className="open-pdf" onClick={gotoDetail}>
              Read More
            </button>
          </a>
        )}
        {type === 'e-book' && className !== 'latest-content' && findPDFLink(item.content.rendered) === '' && (
          <button className="open-pdf" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'e-book' && className !== 'latest-content' && findPDFLink(item.content.rendered) !== '' && (
          <>
            <a href={findPDFLink(item.content.rendered)} target="_blank" rel="noopener noreferrer">
              <button className="open-pdf">
                Read More
              </button>
            </a>
          </>
        )} */}
        {type === 'press' && (
          <button className="press-r-read-more" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'article' && (
          <button className="article-read-more" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'customer-story' && (
          <button className="customer-story-read-more" onClick={gotoDetail}>
            Read More
          </button>
        )}
        {type === 'podcast' && (
          <button className="podcast-read-more" onClick={gotoDetail}>
            Read More
          </button>
        )}


        {(type === 'webinar' && !comingSoon(type, item.title.rendered)) && (
          <button className="webinar-read-more" onClick={gotoDetail}>
            Go To Webinar
          </button>
        )}
      
        {(comingSoon(type, item.title.rendered)) &&
          <a href={getRegistrationLink(item)} target='_blank' rel="noopener noreferrer">
             <button className={`webinar-read-more`} >
            Register Now
          </button>
          </a> 
        }

        
      </div>
    </Card>
  )
}

export default ItemLayoutA