import React, { useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import Salesforce from './Salesforce'
import Sitecore from './Sitecore'
import GoogleCloud from './GoogleCloud'
import Mulesoft from './Mulesoft'
import MainLayout from '../../layouts/MainLayout.js'
import './style.scss'

const Platforms = () => {
  let { path } = useRouteMatch();
  let { pathname } = useLocation();
  useEffect(() => {
    // if (!!pathname.replace('/platforms', '')) {
    //   var el = document.getElementById('partnerSection');
    //   el.scrollIntoView({behavior: 'smooth'});
    // } else {
      window.scrollTo(0, 0)
    // }
  }, [pathname])
  return (
    <div className="platforms">
      <MainLayout>
      <Helmet>
          <title>Aquient | Platforms</title>
          <meta name="description" content="We work with world class partners" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/platforms" />
          <meta name="og:title" content="Aquient | Platforms"/>
          <meta name="og:description" content="We work with world class partners" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Platforms" />
          <meta name="twitter:description" content="We work with world class partners" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container className="text-center platforms-hero">
          <h6 className="text-uppercase">PLATFORMS</h6>
          <h1>We work with world class partners</h1>
          <h2 className="font-normal">Aquient teams up with the world&#39;s most experienced technology platform partners to deliver
            the best strategies, user experience and functionality for our clients.
          </h2>
        </Container>
        <div id="partnerSection" className="partner-section">
          <Switch>
            <Route exact path={path}><Salesforce /></Route>
            <Route path={`/platforms/salesforce`}><Salesforce /></Route>
            <Route path={`/platforms/sitecore`}><Sitecore /></Route>
            <Route path={`/platforms/google-cloud`}><GoogleCloud /></Route>
            <Route path={`/platforms/mulesoft`}><Mulesoft /></Route>
          </Switch>
        </div>
      </MainLayout>
    </div>
  )
}

export default Platforms
