import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import dateFormat from 'dateformat'
import classNames from 'classnames'
import { getMedia } from '../../../actions'
import TagList from '../../../pages/Blog/TagList'
import NewsletterBox from '../../NewsletterBox'
import { ReactComponent as ArticleIcon } from '../../../assets/icons/insights/article.svg'
import { ReactComponent as PodcastIcon } from '../../../assets/icons/insights/podcast.svg'
import { ReactComponent as WebinarIcon } from '../../../assets/icons/insights/webinar.svg'
// import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg'
import {comingSoon} from '../../../helpers/index'
import {getRegistrationLink} from '../../../helpers/index'
import { ReactComponent as EbookIcon } from '../../../assets/icons/insights/ebook.svg'
import { ReactComponent as PressReleaseIcon } from '../../../assets/icons/insights/pressrelease.svg'
import { ReactComponent as VideoIcon } from '../../../assets/icons/insights/video.svg'
import './style.scss'

const getButtonText = (type) => {
  switch (type) {
    case 'podcast': 
        return 'GO TO EPISODE';
    case 'article':
        return 'VIEW FULL ARTICLE';
    case 'customer-story':
        return 'VIEW CUSTOMER STORY';
    case 'webinar':
        return 'GO TO WEBINAR';
    default:
        return 'READ MORE';
  }
};




const ItemLayoutB = ({type, item, tags, isFirst, link1 = '/'}) => {
  const [thumbImg, setThumbImg] = useState('')
  const history = useHistory()

  useEffect(() => {
    (async function() {
      if (item.id) {
        let size = 'medium'
        if (window.innerWidth <= 480) {
          size = 'small'
        }
        let imgLink = await getMedia(item.featured_media, size);
        if (imgLink === undefined) {
          imgLink = ''
        }
        setThumbImg(imgLink);
      }
    })()
  }, [item])

  const actionBtnText = getButtonText(type);


  
 


  const gotoDetail = () => {
    if (!comingSoon(type,item.title.rendered)) {
      history.push(link1)
    }
  }

  const blogTag = [];
  for (let i = 0; i < tags.length; i++) {
    const currentTag = tags[i];
    if(item.tags.includes(currentTag.id)) {
      blogTag.push(currentTag);
    }
  }




  return (
    <div className="item-wrapper-B">
      <Row>
        <Col lg={10}>
          <Row>
            <Col lg={3} md={3} className="thumbnail">
              <div
                className={classNames('default-icon-wrapper', { haveImg: thumbImg !== '' })}
                onClick={gotoDetail}
              >
                {thumbImg !== '' && (
                  <img className="media-img" src={thumbImg} alt="" />
                )}
                {(type === 'article' || type === 'customer-story') && thumbImg === '' && (
                  <div className="article">
                    <ArticleIcon fill="#fff" />
                    <span>Article</span>
                  </div>
                )}
                {type === 'podcast' && thumbImg === '' && (
                  <div className="podcast">
                    <PodcastIcon fill="#fff" />
                    <span>Podcast</span>
                  </div>
                )}
                {type === 'e-book' && thumbImg === '' && (
                  <div className="e-book-thumb">
                    <EbookIcon fill="#fff" />
                    <span>Ebooks</span>
                  </div>
                )}
                 {type === 'webinar' && thumbImg === '' && (
                  <div className="webinar-thumb">
                    <WebinarIcon fill="#fff" />
                    <span>Webinar</span>
                  </div>
                )}
                {type === 'press' && thumbImg === '' && (
                  <div className="press-r-thumb">
                    <PressReleaseIcon fill="#fff" />
                    <span>Press Release</span>
                  </div>
                )}
                {type === 'video' && thumbImg === '' && (
                  <div className="video-thumb">
                    <VideoIcon fill="#212529" />
                    <span>Videos</span>
                  </div>
                )}
              </div>
              <TagList tagCollection={blogTag} /> 
            </Col>
            <Col lg={6} md={9} className="content">
              {comingSoon(type, item.title.rendered) ? <strong className="date">Coming Soon</strong> : <strong className="date">{dateFormat(item.date, "mmmm dS, yyyy")}</strong>}
              

              {comingSoon(type, item.title.rendered) ? <h3 dangerouslySetInnerHTML={{ __html: item.title.rendered.split('|')[0] }}></h3> : <Link to={link1} style={{ textDecoration: 'none' }}>
                <h3 dangerouslySetInnerHTML={{ __html: item.title.rendered.split('|')[0] }}></h3>
              </Link>}
              
              
              <p dangerouslySetInnerHTML={{ __html: item.excerpt.rendered.replace('[&hellip;]', `<a style="color:blue" href="${link1}">[read more]</a>`)}}/>
              <div className="tag-list-mobile">
                <TagList tagCollection={blogTag} /> 
              </div>
              {/* {type === 'podcast' && (
                <div className="view-transcript" onClick={gotoDetail}>
                  <button>
                    <PaperIcon />
                    <span>View the full transcript here</span>
                  </button>
                </div>
              )} */}
              <div className="actions-mobile">
                {comingSoon(type, item.title.rendered) ? 
                <a href={getRegistrationLink(item)} target='_blank'  rel="noopener noreferrer">
                  <Button
                  variant="outline-primary"
                >
                  REGISTER NOW
                </Button> 
                </a>:
                <Button
                  as={Link}
                  to={link1}
                  variant="outline-primary"
                >
                  {actionBtnText}
                </Button> }
              </div>
            </Col>
            <Col lg={3} className="actions">
              {comingSoon(type, item.title.rendered) ? 
              <a href={getRegistrationLink(item)} target='_blank' rel="noopener noreferrer">
                <Button
              variant="outline-primary"
            >
              REGISTER NOW
            </Button>
              </a>
            :
            <Button
                as={Link}
                to={link1}
                variant="outline-primary"
              >
                {actionBtnText}
              </Button>}
            </Col>
          </Row>
        </Col>
        <Col lg={2} className='newsletter-wrapper'>
          {isFirst && (
            <NewsletterBox />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default ItemLayoutB
