import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as Cloud } from '../../../assets/icons/cloud.svg'
import { ReactComponent as Design } from '../../../assets/icons/design.svg'
import { ReactComponent as Implementation } from '../../../assets/icons/implementation.svg'
import { ReactComponent as Journey } from '../../../assets/icons/journey.svg'
import { ReactComponent as PageGraph } from '../../../assets/icons/page-graph.svg'
import { ReactComponent as Prototype } from '../../../assets/icons/prototype.svg'
import './style.scss'

const WhatWeDoTransform = () => {
  return (
    <div className="wwd-transform">
      <div className="bg-peach">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <h1 className="crit-title">Transform</h1>
              <p className="mb-5"><b>Bring opportunities to life with design and technology</b></p>
            </Col>
            <Col lg={6}>
              <p>
                It’s a connected age, and brands are defined now by their Customer Experience – the coal
                face of where an interaction with your brand actually happens. Aquient leverages its extensive
                and trusted partnerships to bring the expertise to connect and integrate complicated systems
                of data, design / content, technology and people / process that spur custom action.
              </p>
              <p>
                By putting the customer at the centre of a transformation initiative, <b>we create internal
                stakeholder alignment, ensure adoption and most importantly, future proofs your investment.</b>
              </p>
            </Col>
          </Row>
          <div className="image-container">
          </div>
        </Container>
      </div>

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>SERVICE</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><Journey /></div>
            <p><b>Audience Journeys</b></p>
            <p>
              Automated audience journeys can save your marketing team time and money, engage your
              customers in more meaningful and personalised ways, all while optimising email communications
              in real time using intelligent analytics and data science. Aquient can helpdesign, automate,
              build and optimise your onboarding, activation, abandon cart, retention, re-engagement pathways
              and more.
            </p>
          </Col>
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><Prototype /></div>
            <p><b>Content Strategy</b></p>
            <p>
              What content should you create, and why? Aquient can help you answer these questions from a data
              driven, strategic standpoint, formulate a content strategy and execute into tangible and
              quantifiable results.
            </p>
          </Col>
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><Cloud /></div>
            <p><b>Enterprise Technology</b></p>
            <p>Technology roadmapping</p>
            <p>Platform evaluation &amp; selection</p>
            <p>Optimisation &amp; quick wins</p>
            <p>Product development</p>
            <p>Software architecture</p>
            <p>Cloud security</p>
            <p>Data Engineering</p>
            <p>Conversational Interfaces</p>
            <p>Digital capabilities assessment</p>
            <p>Tech innovation enablement</p>
            <p>DevOps</p>
            <p>Artificial Intelligence</p>
            <p>Innovation Acceleration</p>
            <p>IoT</p>
          </Col>
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><PageGraph /></div>
            <p><b>Customer Data Architecture</b></p>
            <p>
              One of the biggest challenges businesses of today face is not gathering data, but
              curating it and distilling it into meaningful and useful insights. Aquient’s
              solution architects and data scientists can help with this, from your data
              architecture through to your data insights and analysis.
            </p>
          </Col>
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><Design /></div>
            <p><b>Experience Design</b></p>
            <p>Digital products</p>
            <p>Mobile &amp; connected device apps</p>
            <p>Service design</p>
            <p>Responsive websites</p>
          </Col>
          <Col sm={6} md={4} lg={3} className="mt-5 text-center">
            <div className="mb-4"><Implementation /></div>
            <p><b>Experience Engineering</b></p>
            <p>
              Aquient can help you to reduce customer effort (both perceived and real) by actively
              guiding customers through potentially difficult interactions to ensure their needs are met.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhatWeDoTransform
