import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import salesforceLogo from '../../../assets/images/partner-salesforce.png'
import img1 from '../../../assets/images/salesforce1.png'
import './style.scss'

const Salesforce = () => {
  return (
    <div className="platform-salesforce">
      <hr />
      <Container>
        {/* <Row>
          <Col>
            <h1>Salesforce</h1>
          </Col>
        </Row> */}
        <Row className="flex-column-reverse flex-xl-row">
          <Col xl={5}>
            <h1>Salesforce</h1>
            <p>Our holistic approach to Salesforce is the secret to your success. Work with us to
              implement the Salesforce platform, align your organisation, and gain actionable
              insights from your data.
            </p>
            <p><b>Salesforce Expertise</b></p>
            <ul>
              <li>Marketing Cloud</li>
              <li>Advertising Studio</li>
              <li>Audience Studio</li>
              <li>Interaction Studio</li>
              <li>Datorama</li>
              <li>Service Cloud</li>
              <li>Sales Cloud</li>
              <li>Community Cloud</li>
            </ul>
          </Col>
          <Col xl={7} className="mb-5 mb-xl-0 position-relative">
            <img className="img-info" src={img1} alt="" />
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="logo mb-4" src={salesforceLogo} alt="Salesforce Gold Partner" />
          </Col>
        </Row>
      </Container>

      <div className="bg-blue">
        <Container className="text-center">
          <h6 className="mb-5 fc-yellow">SALESFORCE GOLD CONSULTING PARTNER</h6>
          <h2 className="font-normal">
            Using Salesforce, we help our clients better connect with their customers, run
            their business from their phones, create one-to-one customer journeys and make
            smarter, more predictive decisions from anywhere, in real-time.
          </h2>
        </Container>
      </div>

      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h1 className="whatwedo">What we do with Salesforce</h1>
          </Col>
          <Col lg={6}>
            <p><b>Process Design</b><br />
              Reimagine the way your organisation works with modern tools and more efficient processes<br /><br />
              <b>Platform Integration</b><br /><br />
              <b>Experience Design</b><br />
              Design beautiful and intuitive experiences that engage your customers, stakeholders and employees<br /><br />
              <b>Salesforce Implementation</b><br />
              Integrate Salesforce’s out-of-the-box capabilities with your data sets and technologies<br /><br />
              <b>Data &amp; Analytics</b><br />
              Close data gaps, accelerate insights and build your capacity to get ahead of change</p>
          </Col>
        </Row>
      </Container>

      <hr />

      <Container className="text-center">
        <h2>Meet your personal Salesforce team</h2>
        <p className="par-1">With more than 500 years of cumulative experience, we’re a highly skilled team of
          marketing, development, strategy and consulting experts. Your brand is like no
          other, so we build custom solutions and implement Salesforce-based strategies
          that work specifically for you.
        </p>
      </Container>

      <Container className="service-container pt-0">
        <div className="title-line">
          <hr />
          <h6 className="text-center text-uppercase"><span>Here’s what we do:</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center mt-5">
          <Col md={6}>
            <p><b>Integration</b></p>
            <p>Our team of experts connects technologies and makes systems work to their strengths
              to achieve business results.
            </p>
            <p><b>Strategy</b></p>
            <p>One size doesn’t fit all. We understand opportunities and strategic approaches across
              industries to bring clarity to challenges.
            </p>
            <p><b>Implementation</b></p>
            <p>We plan, build and deploy tailored solutiosn that meet both the needs of the organisation
              and its customers
            </p>
          </Col>
          <Col md={6}>
            <p><b>Support</b></p>
            <p>We ensure continued client success through training and ongoing managed services in an
              a-la-carte or full-service manner
            </p>
            <p><b>Performance Management</b></p>
            <p>We will help you get the most out of Datorama, supporting you from ideation to
              implementation
            </p>
            <p><b>Marketing Consulting</b></p>
            <p>Get the most out of your Salesforce investment with expert guidance designed to level up
              your marketing campaigns.
            </p>
          </Col>
        </Row>
        <p className="know-more text-center mt-5">
          Want to know more? Book your personal consultation with one of our Salesforce experts today to
          see how we can help your business.
        </p>
        <Button
          as={Link}
          to={{
            pathname: '/contact-us',
            state: {
              subject: 'Consultation with Salesforce expert',
              description: 'Please contact me using the details above regarding consultation on Salesforce.'
            },
          }}
          className="mt-5"
        >
          Book Now<i className="fas fa-chevron-right" />
        </Button>
      </Container>
    </div>
  )
}

export default Salesforce
