import React, { useEffect } from 'react'
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom'
import ScrollToTopOnMount from '../../routers/ScrollToTopOnMount.js'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import CxDesign from './CxDesign'
import DataScience from './DataScience'
import MachineLearning from './MachineLearning'
import MarketingTechnology from './MarketingTechnology'
import CRM from './CRM'
import Img1 from '../../assets/images/activate21.png'
import './style.scss'

const PracticeAreas = () => {
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace('#', ''));
      el.scrollIntoView();
    }
  }, [hash])
  return (
    <div className="practice-areas">
      <ScrollToTopOnMount />
      <MainLayout>
      <Helmet>
          <title>Aquient | Practice Areas</title>
          <meta name="description" content="Utilising industry best practices and a user-first approach to drive customer values and deliver custom-built experiences." />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/practice-areas" />
          <meta name="og:title" content="Aquient | Practice Areas"/>
          <meta name="og:description" content="Utilising industry best practices and a user-first approach to drive customer values and deliver custom-built experiences." />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Practice Areas" />
          <meta name="twitter:description" content="Utilising industry best practices and a user-first approach to drive customer values and deliver custom-built experiences." />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <CxDesign />
        <DataScience />
        <MachineLearning />
        <img className="d-md-none img-mobile" src={Img1} alt="" />
        <MarketingTechnology />
        <CRM />
      </MainLayout>
    </div>
  )
}

export default PracticeAreas
