import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Home from '../pages/Home'
import WhatWeDo from '../pages/WhatWeDo'
import PracticeAreas from '../pages/PracticeAreas'
import Platforms from '../pages/Platforms'
import Industries from '../pages/Industries'
import Careers from '../pages/Careers'
import Blog from '../pages/Blog'
import CareerItem from '../pages/CareerItem'
import CaseStudies from '../pages/CaseStudies'
import ContactUs from '../pages/ContactUs'
import Insight from '../pages/Insight'
import ArticleSingle from '../pages/Insight/Articles/Details'
import WebinarSingle from '../pages/Insight/Webinars/Details'
import AquientNewsSingle from '../pages/Insight/AquientNews/Details'
import MarketingMentorSingle from '../pages/Insight/MarketingMentors/Details'
import CustomerStorySingle from '../pages/Insight/CustomerStories/Details'
import EbookSingle from '../pages/Insight/Ebooks/Details'
import VideoSingle from '../pages/Insight/Video/Details'
import PressReleaseSingle from '../pages/Insight/PressRelease/Details'
import InsightTags from '../pages/Insight/Tags'
import Legal from '../pages/Legal'
import PrivacyPolicy from '../pages/PrivacyPolicy'
//import DPA from '../assets/files/psa-data-processing-addendum.pdf'
//import Document from '../pages/Document'



const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/what-we-do"><WhatWeDo /></Route>
        <Route path="/practice-areas"><PracticeAreas /></Route>
        <Route path="/platforms"><Platforms /></Route>
        <Route path="/industries"><Industries /></Route>
        <Route path="/careers"><Careers /></Route>
        <Route path="/blog"><Blog /></Route>
        <Route path="/job/:jobId"><CareerItem /></Route>
        <Route path="/case-studies"><CaseStudies /></Route>
        <Route path="/contact-us"><ContactUs /></Route>
        <Route path="/insights/articles/:slug"><ArticleSingle /></Route>
        <Route path="/insights/webinars/:slug"><WebinarSingle /></Route>
        <Route path="/insights/aquient-news/:slug"><AquientNewsSingle /></Route>
        <Route path="/insights/marketing-mentors/:slug"><MarketingMentorSingle /></Route>
        <Route path="/insights/customer-stories/:slug"><CustomerStorySingle /></Route>
        <Route path='/insights/videos/:slug'><VideoSingle /></Route>
        <Route path='/insights/e-books/:slug'><EbookSingle /></Route>
        <Route path='/insights/press-release/:slug'><PressReleaseSingle /></Route>
        <Route path="/insights/tags"><InsightTags /></Route>
        <Route path="/insights"><Insight /></Route>
        <Route path="/legal"><Legal /></Route>
        <Route path="/privacy-policy"><PrivacyPolicy/></Route>
        <Route path="*">
          Error 404
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes
