import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ScrollToTopOnMount from '../../routers/ScrollToTopOnMount.js'
import Animation from '../../components/Animation'
import MainLayout from '../../layouts/MainLayout.js'
import levelImg from '../../assets/images/home-group.png'
import salesforce from '../../assets/images/partner-salesforce.png'
import sitecore from '../../assets/images/partner-sitecore.jpg'
import googlecloud from '../../assets/images/partner-googlecloud.jpg'
import mulesoft from '../../assets/images/partner-mulesoft.jpg'
import homelottie from '../../assets/animations/home.json'
import './style.scss'

const Home = () => {

  return (
    <div className="home-page">

      <ScrollToTopOnMount />
      <MainLayout>
      <div style={{'height':'64px','line-height':'64px','background-color':'#c40000','text-align':'center','color':'#FFFFFE'}}>Aquient is now part of <a href="https://k2partnering.com" style={{'color':'white','text-decoration-line':'underline'}}>K2 Partnering Solutions</a></div>
        <Container className="welcome">
          <Row className="align-items-center">
            <Col lg={6}>
              <Animation data={homelottie} />
            </Col>
            <Col lg={6}>
              <h6 className="text-uppercase">
                Welcome to Aquient
              </h6>
              <h1>
                A new kind of customer experience company
              </h1>
              <p>
                Blending strategy, design and technology, we create experiences that have a positive
                impact on people and the businesses that serve them.
              </p>
            </Col>
          </Row>
          <Button as={Link} to="/contact-us" id="contact-us" className="btn-outline-primary text-uppercase d-block d-md-none mt-4">Contact Us</Button>
        </Container>

        <div className="bg-yellow ">
          <Container className="level-up">
            <Row className="align-items-center">
              <Col lg={6} className="mb-5 mb-lg-0">
                <h1>Level up your customer experience</h1>
                <p>
                  People embrace companies that deliver a customer experience that best meets their needs. Aquient
                  helps companies transform by designing best-in-class experiences, driven by strong creative
                  and with a foundation in data, technology and business strategy required for customer experience
                  excellence. This user-centric approach is changing industries by creating unified brand
                  experiences that foster more meaningful relationships with users.
                </p>
                <Button as={Link} to="/what-we-do">Learn More<i className="fas fa-chevron-right" /></Button>
              </Col>
              <Col lg={6}>
                <div className="level-image">
                  <img src={levelImg} alt="levelimg" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="partner">
          <Row className="align-items-center">
            <Col lg={6} className="mb-5 mb-lg-0">
              <h1>We partner with the best in the business</h1>
              <p>
                We team up with Salesforce, Google, Sitecore and Mulesoft because they are just as focussed as
                us on working collaboratively to build high quality solutions for you. That means we aren’t
                just software, we aren’t just implementation, we are results.
              </p>
            </Col>
            <Col lg={6} className="partner-images">
              <Row>
                <img src={salesforce} alt="Salesforce Gold Consulting Partner"/>
              </Row>
              <Row>
                <Col><img src={sitecore} alt="Sitecore Certified Solutions Partner"/></Col>
                <Col><img src={googlecloud} alt="Google Cloud Partner"/></Col>
              </Row>
              <Row>
                <img src={mulesoft} alt="Mulesoft Partner"/>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  )
}

export default Home
