import React, { useEffect } from 'react'
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import './style.scss'
import File1 from '../../assets/files/aquient-psa.pdf'
import File2 from '../../assets/files/psa-data-processing-addendum.pdf'
import File3 from '../../assets/files/psa-security-privacy-and-architecture.pdf'


const Legal = () => {
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace('#', ''));
      el.scrollIntoView();
    }
    else window.scrollTo(0, 0)
  }, [hash])
  
  return (
    <div className="legal-page">
      <MainLayout>
      <Helmet>
          <title>Aquient | Legal</title>
          <meta name="description" content="Aquient's list of legal documents" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/legal" />
          <meta name="og:title" content="Aquient | Legal"/>
          <meta name="og:description" content="Aquient's list of legal documents" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Legal" />
          <meta name="twitter:description" content="Aquient's list of legal documents" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container className="intro">
          <Row className="align-items-center">
            <Col lg={6}>
              <h6 className="text-uppercase">LEGAL</h6>
              <h1>Legal Documents</h1>
              <p>
                Find Aquient's list of legal documents below.
              </p>
            </Col>
            <Col lg={6} className="mt-5 mt-lg-0">
            </Col>
          </Row>
        </Container>

        <Container id="legal-docs" className="service-container pt-0">
          <div className="title-line">
            <hr />
            <h6 className="text-center"><span>LEGAL DOCUMENTS</span></h6>
          </div>
              <Row>
                <Col className="mt-5">
                  <p><b>Document Title</b></p>
                </Col>
                <Col className="mt-5">
                  <p><b>Link</b></p>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <p>Professional Services Agreement</p>
                </Col>
                 <Col className="mt-3">
                  <p><a href={File1} target="_blank" rel="noopener noreferrer"><b>aquient-psa.pdf</b></a></p>
                 </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <p>Professional Services Data Processing Addendum</p>
                </Col>
                 <Col className="mt-3">
                  <p><a href={File2} target="_blank" rel="noopener noreferrer"><b>psa-data-processing-addendum.pdf</b></a></p>
                 </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <p>Professional Services Security, Privacy and Architecture</p>
                </Col>
                 <Col className="mt-3">
                   <p><a href={File3} target="_blank" rel="noopener noreferrer"><b>psa-security-privacy-and-architecture.pdf</b></a></p>
                 </Col>
              </Row>


              <p className="mt-5">If you need a document not listed here, please reach out to&nbsp;
              <a href="mailto:legal@aquient.asia" target="_blank" rel="noopener noreferrer">legal@aquient.asia</a>
              </p>
              
        </Container>

      </MainLayout>
    </div>
  )
}

export default Legal
