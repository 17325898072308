import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const MainLayout = ({
  children,
  header = <Header />,
  footer = <Footer />
}) => {
  return (
    <>
      {header}
      <div style={{ height: '64px' }}/>
      {children}
      {footer}
    </>
  )
}

export default MainLayout
