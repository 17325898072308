import React, { useEffect } from 'react'
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import Img1 from '../../assets/images/industries.png'
import './style.scss'

const Industries = () => {
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace('#', ''));
      el.scrollIntoView();
    }
    else window.scrollTo(0, 0)
  }, [hash])
  return (
    <div className="industries-page">
      <MainLayout>
      <Helmet>
          <title>Aquient | Industries</title>
          <meta name="description" content="Your industry is ready for a refresh" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/industries" />
          <meta name="og:title" content="Aquient | Industries"/>
          <meta name="og:description" content="Your industry is ready for a refresh" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Industries" />
          <meta name="twitter:description" content="Your industry is ready for a refresh" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container>
          <Row className="align-items-center flex-column-reverse flex-lg-row d-none d-lg-flex">
            <Col lg={6} className="mb-5" >
              <img src={Img1} alt=""></img>
            </Col>
            <Col lg={6}>
              <h6 className="text-uppercase">
                INDUSTRIES
              </h6>
              <h1>
                Your industry is ready for a refresh
              </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="d-lg-none">
              <h6>
                INDUSTRIES
              </h6>
              <h1>
                Your industry is ready for a refresh
              </h1>
            </Col>
            <Col lg={6}>
              <h2 className="font-normal">
                Trust is the most powerful currency in business, but unfortunately consumer trust in
                brands has been eroded. Brands can reclaim the trust they’ve lost by building better
                customer experiences.
              </h2>
            </Col>
            <Col lg={6}>
              <p>
                Industries must partner with customers to co-create the future with those customers as
                a new value interchange takes shape: customers will reward companies that have meaningful
                purpose, cultivate sustainable relationships and do what’s right by people.
              </p>
              <img className="d-lg-none mt-5" src={Img1} alt=""></img>
            </Col>
          </Row>
        </Container>

        <div id="financial-services" className="bg-lightgrey">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="mb-5">
                <h1>Financial Services</h1>
                <p><b>
                  Financial services brands live amid changing regulations, expectations and constant
                  disruption from new and non-traditional competitors.
                </b></p>
                <p>
                  In order to compete, financial services brands must re-examine their end-to-end customer
                  journey and create and ethos of humanity in an increasingly digital sphere.
                </p>
              </Col>
              <Col lg={6}>
                <p><b>Customer trends we are seeing in the financial services industry:</b></p>
                <ul>
                  <li>
                    Customers want – and expect – operational ease in managing their finances. This also
                    helps satisfy their demands for transparency.
                  </li>
                  <li>
                    Customers continue to clamour for highly personalised, omnichannel solutions from their
                    financial institutions that function in real time – when and where they want it – to help
                    them get where they want to go.
                  </li>
                  <li>
                    They have a huge appetite for intuitive UX experiences and tools that help them feel in
                    control of their financial well-bring and on track toward their goals
                  </li>
                  <li>
                    Disruption and reinvention will be key to sustaining relevance. As external players continue
                    to reset customer expectations, it will be critical to reimagine products, services and scope
                    to meet modern customer needs.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Container id="retail-consumer-goods">
          <Row className="align-items-center">
            <Col lg={6} className="mb-5">
              <h1>Retail &amp; Consumer Goods</h1>
              <p><b>
                Moving from retail transactions to retail experiences.
              </b></p>
            </Col>
            <Col lg={6}>
              <p><b>
                In a market where retailers are pressured by behemoths like Alibaba, Amazon and digitally
                native brands, how can your brand effectively compete?
              </b></p>
              <ul>
                <li>
                  Ask meaningful questions. Go from “how do we increase average order value?” to thinking about
                  how your brand can respond to human truths and change behaviors.
                </li>
                <li>
                  Use tech as a tool, not a strategy. Technology like voice, AI, and VR should make retail
                  experiences feel like fluid extensions of people’s everyday lives, not gimmicks.
                </li>
                <li>
                  Balance intimacy and scale. Some new experiences are better approached with test markets, and
                  others are better rolled out at scale – we can help you evaluate.
                </li>
                <li>
                  Consider your ecosystem. Product, distribution, advertising, and customer service are all parts
                  of the experience – not just in-store or online shopping.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div id="travel-hospitality" className="bg-lightgrey">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="mb-5">
                <h1>Travel &amp; Hospitality</h1>
                <p><b>A smooth journey, a personal touch, and a memorable experience.</b></p>
                <p>
                  Brands have tremendous opportunities to solve for the frustrations travelers face
                  before, during, and after their journey.
                </p>
              </Col>
              <Col lg={6}>
                <p><b>How do you create travel experiences that can impact the most essential moments
                  of a customer’s journey, while creating value in moments they never knew they needed?
                </b></p>
                <ul>
                  <li>
                    Capitalize on excitement (pre-travel) and nostalgia (post-travel) by finding ways to
                    amplify and connect over these organic, positive feelings. Strong visual connections
                    over Instagram and other social media can prolong feelings of goodwill toward your brand.
                  </li>
                  <li>
                    Ensure a frictionless cross-channel experience from exploration to booking to
                    checkout – and make it easy to customize the experience to give travelers greater control
                    over their environment, entertainment, connectivity, and culinary preferences.
                  </li>
                  <li>
                    Use data and artificial intelligence to anticipate needs, address problems in
                    real-time, and provide a personalized concierge for the full travel experience. How can digital
                    experiences and other services mitigate travel pain points and create moments of delight?
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Container id="media-entertainment">
          <Row className="align-items-center">
            <Col lg={6} className="mb-5">
              <h1 className="heading-1">Media &amp; Entertainment</h1>
              <p><b>Goodbye restrictive services. Hello choose your own adventure</b></p>
            </Col>
            <Col lg={6}>
              <p><b>
                The worlds of telecommunications and media seem to change daily and often with little notice. How
                do you create an experience that puts people in control?
              </b></p>
              <ul>
                <li>
                  People feel overwhelmed by fragmented platforms and content streams. To combat this, prioritize
                  access to personally curated content, and give them control over payment model options.
                </li>
                <li>
                  Create opportunities for customers to participate in unique and immersive content, customizing
                  their journey based on their own preferences.
                </li>
                <li>
                  Treat customer data with respect and deliver value, relevance, and convenience in exchange for information.
                </li>
                <li>
                  Be ready for 5G networks’ dramatic improvements in latency, capacity, and reliability – which will
                  open the floodgates for new types of content creation and consumption.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div id="not-for-profit" className="bg-lightgrey">
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <h1>Not for Profit</h1>
                <p><b>We help organizations that strengthen their communities better manage their
                  projects, budgets, and people.
                </b></p>
              </Col>
              <Col lg={6}>
                <p>Our consultants often say their most meaningful projects are when they’re
                  positively impacting their communities. We count many nonprofit organizations
                  among our growing client base—helping them expand their reach and ability to
                  deliver more good in the world.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <Container id="telco">
          <Row className="align-items-center">
            <Col lg={6} className="mb-5">
              <h1>Telco</h1>
              <p><b>We help telecommunication leaders put the latest technology and thinking to
                work for their customers, employees, and organisations.
              </b></p>
            </Col>
            <Col lg={6}>
              <p><b>
                The worlds of telecommunications and media seem to change daily and often with
                little notice. How do you create an experience that puts people in control?
              </b></p>
              <ul>
                <li>
                  People feel overwhelmed by fragmented platforms and content streams. To combat
                  this, prioritize access to personally curated content, and give them control
                  over payment model options.
                </li>
                <li>
                  Create opportunities for customers to participate in unique and immersive
                  content, customizing their journey based on their own preferences.
                </li>
                <li>
                  Treat customer data with respect and deliver value, relevance, and convenience
                  in exchange for information.
                </li>
                <li>
                  Be ready for 5G networks’ dramatic improvements in latency, capacity, and
                  reliability – which will open the floodgates for new types of content creation
                  and consumption.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  )
}

export default Industries
