import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout.js/index.js";
import "./style.scss";

const PrivacyPolicy = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace("#", ""));
      el.scrollIntoView();
    } else window.scrollTo(0, 0);
  }, [hash]);

  return (
    <div className="privacy-page">
      <MainLayout>
        <Helmet>
          <title>Aquient | Privacy</title>
          <meta name="description" content="Privacy policy of Aquient" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/privacy" />
          <meta name="og:title" content="Aquient | Privacy" />
          <meta name="og:description" content="Privacy policy of Aquient" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Privacy" />
          <meta
            name="twitter:description"
            content="Privacy policy of Aquient"
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>

        <Container>
          <div className="privacy">
            <h2 className="policy header">Privacy Policy</h2>
            <div className="policies">
              <p>
                Aquient is strongly committed to protecting your privacy and
                providing a safe online experience for all the users accessing
                our website. This Privacy Policy discloses what information we
                may gather and how we may use/share it. Please read the policy
                completely.
              </p>

              <p>
                Note: By accessing our website, you have consented to this
                Privacy Policy. If for some reason, you do not agree to this
                policy, you must not access our website.
              </p>

              <p>What information do we may collect & how we may use it?</p>

              <div className="policy">
                <h3>Personally Identifiable Information: </h3>
                You may browse our website without revealing any personally
                identifiable information about yourself. We do not collect any
                personally identifiable information about you unless you
                voluntarily choose to enter your personal information on our
                website. This data may include your name, email address, and
                contact number. This collected information is used to respond to
                your query.
              </div>
              
              <div className="policy">
                <h3>Non-Personally Identifiable Information: </h3>
                We do collect certain non-personally identifiable information
                such as system information, carrier information, and web log
                information for all visitors of our website. We may use this
                information to analyse trends and statistics (such as the number
                of visits, average time spent on the site, pages viewed, etc.)
                so as to offer you improved services.
              </div>
              <div className="policy">
                <h3>How we may share the collected information? </h3>
                <p>
                  We do not sell, rent or share your personal information with
                  any of the third parties. Although, we reserve the right to
                  disclose your information without notice if required to
                  satisfy any law or legal request, to conduct an investigation,
                  to protect the integrity of our website/property, and to
                  protect the safety of our visitors.
                </p>
                <p>
                  Further, in case, if we sell all or part of our business, sell
                  or transfer our assets, or are otherwise involved in a merger
                  or business transfer, we may share your information with the
                  third-parties involved in such transactions.{" "}
                </p>
              </div>

              <div className="policy">
                <h3>How we may use the collected information? </h3>
                <p>
                  We may use your information for operating our business and
                  utilising it for other legitimate purposes as permitted by the
                  applicable law. Some ways in which your information may be
                  used are
                </p>
                <ul>
                  <li>
                    To enable smooth communication with you regarding our
                    products or services, responding correctly to your
                    inquiries, assist you in completing transactions, processing
                    payments, and other customer service purposes
                  </li>
                  <li>
                    To gain better insight into customer behaviour, their
                    preferences, and dislikes in order to align our website,
                    products and services and to carry out accurate research and
                    analysis.
                  </li>
                  <li>
                    To send marketing and promotional communications including
                    newsletters, promotional campaigns, customer satisfaction
                    surveys and other communications related to our business,
                    including information about our solutions we think may
                    interest you.
                  </li>
                </ul>
              </div>

              <div className="policy">
                <h3>Information Security</h3>
                <p>
                  We take all the possible measures to protect the information
                  provided by you and ensure that it is not misused in any way,
                  by any means or possibilities. All the information provided by
                  you is stored on a secured web server managed by us. We use
                  reasonable administrative and technical security measures to
                  protect your information from unauthorised access, loss or
                  damage.
                  <br />
                  However, due to the nature of information technology, any data
                  present on the internet is prone to leakage. Hence, we cannot
                  guarantee the security of your personal information and
                  explicitly disclaim any such obligation.
                </p>
              </div>

              <div className="policy">
                <h3>GDPR Compliance</h3>
                <p>
                  This section applies to data processing activities of clients
                  in the European Economic Area (EEA). Our website is compliant
                  with all relevant US and EU technical, security, and legal
                  regulations including GDPR.{" "}
                </p>
                <p>
                  Effective from May 25, 2018, the privacy of European Union
                  based clients is guaranteed by the EU General Data Protection
                  Regulation (GDPR).{" "}
                </p>
              </div>

              <div className="policy">
                <h3>Children’s Privacy</h3>
                <p>
                  Our website is not intended for children below sixteen (16)
                  years of age. Therefore, we do not knowingly collect any
                  personal information from children without proper parental
                  consent.
                </p>
                <p>
                  Also, if you believe that we may have collected personal
                  information from someone under the age of 16 without parental
                  consent, please let us know and we will take appropriate
                  measures to address the issue promptly and remove the
                  information.
                </p>
              </div>

              <div className="policy">
                <h3>Changes to this Privacy Policy</h3>
                <p>
                  We reserve the right to modify and update our Privacy Policy
                  as and when needed, so as to reflect our current privacy
                  practices. We can change the policy any time without sending a
                  prior notice. Hence, we recommend you to regularly visit this
                  page to stay updated with the latest Privacy Policy.
                </p>
              </div>

              <div className="policy">
                <h3>Contact Us</h3>
                <p>
                  We respect your privacy and, in the event you do not wish to
                  keep your information with us, we provide you the option to
                  “opt out” of your data by sending a mail to <a href="/contact-us">Aquient | Contact Us</a>. If you have any queries regarding our Privacy Policy, feel
                  free to reach out via our Contact Us page.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </MainLayout>
    </div>
  );
};

export default PrivacyPolicy;
