import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../../../assets/images/data-science.png'
import { ReactComponent as Tactic } from '../../../assets/icons/tactic.svg'
import { ReactComponent as Graph } from '../../../assets/icons/line-graph.svg'
import { ReactComponent as Computer } from '../../../assets/icons/computers-ring.svg'
import { ReactComponent as Database } from '../../../assets/icons/database-science.svg'
import { ReactComponent as List } from '../../../assets/icons/list.svg'
import { ReactComponent as Device } from '../../../assets/icons/cloud-device.svg'
import { ReactComponent as Setting } from '../../../assets/icons/cloud-setting.svg'
import './style.scss'

const DataScience = () => {
  return (
    <div id="data-science" className="data-science">
      <div className="bg-lightgrey">
        <Container>
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <Col lg={6}>
              <h6 className="text-uppercase">PRACTICE AREAS</h6>
              <h1>Data Science</h1>
              <p>
                Aquient’s team of data scientists and analysts use data as a <b>key input to drive decision
                making</b> and to design effective brand experiences.
              </p>
            </Col>
            <Col lg={6} className="mb-5 mb-lg-0">
              <img src={img1} alt='' />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={6}>
              <h2 className="font-normal">
                Everyone is talking about data-driven decision-making, but we help you actually
                make data-driven decisions. This often means making a shift internally to a culture of
                increased data equity.
              </h2>
            </Col>
            <Col lg={6}>
              <p>
                By using data science to map customer needs to business objectives, we gain critical insight
                and the ability to build sustainable business growth. When your internal culture is
                data-led, the data framework and analysis can power faster and more confident decision making.
              </p>
              <p>
                Data strategy and modelling is also an important part of a data first culture supported by the
                right technical approach to data management and operationalisation of analytics.
              </p>
              <p>
                Data-driven design which embraces a test and optimise cycle will drive superior brand experiences
                and improve business results. Data is a monetizable asset, and one of the key inputs required to
                create highly personalised experience. By integrating machine learning algorithms that are automated
                and constantly evolve.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="service-container">
          <div className="title-line">
            <hr />
            <h6 className="text-center"><span>SERVICE</span></h6>
          </div>
          <Row className="flex-wrap justify-content-center">
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Tactic /></div>
              <p className="font-normal text-center"><b>Data Strategy</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Graph /></div>
              <p className="font-normal text-center"><b>Data Visualisation</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Computer /></div>
              <p className="font-normal text-center"><b>Data Mining &amp; Modelling</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Database /></div>
              <p className="font-normal text-center"><b>Data Monetisation</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><List /></div>
              <p className="font-normal text-center"><b>Brand &amp; Social Analytics</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Device /></div>
              <p className="font-normal text-center"><b>Platform Implementation</b></p>
            </Col>
            <Col xs={6} md={4} lg={3} className="mt-5">
              <div className="text-center mb-4"><Setting /></div>
              <p className="font-normal text-center"><b>Machine Learning &amp; AI</b></p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-blue">
        <Container className="text-center">
          <h6>ANAYLTICS</h6>
          <h2 className="font-normal mt-4">
            There’s no guesswork to what we do. Everything is rooted in data
            points that help illuminate the path <span className="d-lg-none">forward</span>
          </h2>
        </Container>
      </div>
    </div>
  )
}

export default DataScience
