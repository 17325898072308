import { apiUrl } from '../apis'

export const getAllPosts = async (page = 1, perPage = 100) => {
  return fetch(`${apiUrl}/posts?page=${page}&per_page=${perPage}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (!data?.data?.status === 404 || data.length > 0) {
        return data
      }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getPostsByCategory = async (id, page = 1, perPage = 100) => {
  return fetch(`${apiUrl}/posts?categories=${id}&page=${page}&per_page=${perPage}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (!data?.data?.status === 404 || data.length > 0) {
        return data
      }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getPostsByTag = async (id, page = 1, perPage = 100) => {
  return fetch(`${apiUrl}/posts?tags=${id}&page=${page}&per_page=${perPage}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (!data?.data?.status === 404 || data.length > 0) {
        return data
      }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getAllTags = async () => {
  return fetch(`${apiUrl}/tags?per_page=100`, {
    method: 'GET',
  }).then(response => response.json()).then(data => {
    if (!data?.data?.status === 404 || data.length > 0) {
      return data
    }
  }).catch(err => {
    console.error('Error:', err)
  })
}

export const getSinglePostBySlug = async (slug) => {
  return fetch(`${apiUrl}/posts?slug=${slug}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (!data?.data?.status === 404 || data.length > 0) {
        return data
      }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getAuthorByLink = async (link) => {
  return fetch(link, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
    if (data) {
      return data
    }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getMedia = async (id, size = 'medium') => {
  if (id && id !== 0) {
    return fetch(`${apiUrl}/media/${id}`, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        if (size === 'medium' && data.media_details.sizes.medium_large) {
          return data.media_details.sizes.medium_large.source_url
        }
        // if (size === 'small' && data.media_details.sizes.medium) {
        //   return data.media_details.sizes.medium.source_url
        // }
        return data.media_details.sizes.full.source_url
      }
      })
    .catch(err => {
      console.error('Error:', err)
    })
  }
  return ''
}

export const getAllCategories = async (page = 1, perPage = 100) => {
  return fetch(`${apiUrl}/categories?page=${page}&per_page=${perPage}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (!data?.data?.status === 404 || data.length > 0) {
        return data
      } return []
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

export const getCategoryId = async (slug) => {
  return fetch(`${apiUrl}/categories?slug=${slug}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
      if (data && data.length) {
        return data[0].id
      } else {
        return 0
      }
    })
  .catch(err => {
    console.error('Error:', err)
  })
}

