import React from 'react'
import './style.scss'

const List = ({items, icon}) => {
  return (
    <ul className="aq-list">
      {items.map((item, i) => (
        <li key={i.toString()}>
          <i>{icon}</i>{item}
        </li>
      ))}
    </ul>
  )
}

export default List
