import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { useLocation, Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import Img1 from '../../assets/images/career1.png'
import { apiUrl } from '../../apis'
import './style.scss'

const Careers = () => {
  const { hash } = useLocation()
  const [ jobs, setJobs ] = useState()
  useEffect(() => {
    if (hash) {
      var el = document.getElementById(hash.replace('#', ''));
      el.scrollIntoView();
    }
    else window.scrollTo(0, 0)
  }, [hash])
  useEffect(() => {
    fetch(`${apiUrl}/job/`, {
      method: 'GET',
    }).then(response => response.json()).then(data => {
      if (!data?.data?.status === 404 || data.length > 0) setJobs(data)
    }).catch(err => {
      console.error('Error:', err)
    })
  }, [])

  return (
    <div className="careers-page">
      <MainLayout>
      <Helmet>
          <title>Aquient | Careers</title>
          <meta name="description" content="We believe what’s good for people is good for business" />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/careers" />
          <meta name="og:title" content="Aquient | Careers"/>
          <meta name="og:description" content="We believe what’s good for people is good for business" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Careers" />
          <meta name="twitter:description" content="We believe what’s good for people is good for business" />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <Container className="intro">
          <Row className="align-items-center">
            <Col lg={6}>
              <h6 className="text-uppercase">CAREERS</h6>
              <h1>We believe what’s good for people is good for business</h1>
              <p>
                Bring your whole self to work – no matter its colour, shape or medley – and
                create industry leading experiences that inspire.
              </p>
            </Col>
            <Col lg={6} className="mt-5 mt-lg-0">
              <div className="img-container"><img src={Img1} alt=""></img></div>
            </Col>
          </Row>
        </Container>

        <Container id="open-positions" className="service-container pt-0">
          <div className="title-line">
            <hr />
            <h6 className="text-center"><span>OPEN POSITIONS</span></h6>
          </div>
          {!!jobs && (
            <>
              <Row>
                <Col className="mt-5">
                  <p><b>Job</b></p>
                </Col>
                <Col className="mt-5">
                  <p><b>Location</b></p>
                </Col>
              </Row>
              {jobs?.map(job => (
                <Row key={`job-${job.id}`} >
                  <Col className="mt-3">
                    <p><Link to={`/job/${job.id}`}><b dangerouslySetInnerHTML={{ __html: job.title.rendered}}></b></Link></p>
                  </Col>
                  <Col className="mt-3">
                    <p>{job.location}</p>
                  </Col>
                </Row>
              ))}
            </>
          )}
          {(!jobs || !jobs.length) && (
            <>
              <p className="mt-5">There are currently no open positions, but we are always on the lookout for
              talent. If you feel like you have what it takes to be part of the Aquient
              team, reach out to&nbsp;
              <a href="mailto:careers@aquient.asia" target="_blank" rel="noopener noreferrer">careers@aquient.asia</a>
              </p>
            </>
          )}
        </Container>

        <Container id="our-values" className="pt-0">
          <h1 className="w-100 text-center mb-5">Our Values</h1>
          <Row>
            <Col md={6}>
              <p><b>We hire differently</b></p>
              <p>
                Most companies operate under the premise that
                employees should be replaceable like parts of an
                assembly line. We choose our people more carefully.
                We bring them in if we think they're a good fit,
                regardless of whether we have work for them right
                away.
              </p>
              <p><b>We work together</b></p>
              <p>
                Our flat structure calls for it by necessity. Being a leader
                may feel unnatural at first, but we expect everyone to
                step up and own part of the project. It's kind of like
                playing basketball: When someone passes you the ball,
                you're in charge of what to do with it next.
              </p>
              <p><b>Ideas over egos</b></p>
              <p>Listen to others and save your energy for what matters.</p>
            </Col>
            <Col md={6}>
              <p><b>Embrace the unknown</b></p>
              <p>
                The future is uncertain, but you can be ready for it.
                Take chances: You can't be right until you know what's
                wrong.
              </p>

              <p><b>Speak up</b></p>
              <p>
                Don't be afraid to ask for help. No matter the
                commitments and distractions, we're always there to
                listen. But don't rely on anyone to hold your hand.
                Asking for help when needed is a sign of strength, but
                we believe in you, we hired you for a reason. We're all in
                this together.
              </p>

              <p><b>Play is important</b></p>
              <p>
                We are adults, that doesn't mean we can't have fun.
                As long as we do great work, that's all that matters.
              </p>
            </Col>
                
          </Row>
        </Container>
      </MainLayout>
    </div>
  )
}

export default Careers
