import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './style.scss'
import dateFormat from 'dateformat';
import TagList from '../TagList'

const BlogList = ({ blogCollection, tags }) => {

  return(
    <div className="blog-list">
      {blogCollection.map((blog, index) => {
      const blogTag = [];
      for (let i = 0; i < tags.length; i++) {
        const currentTag = tags[i];
        if(blog.tags.includes(currentTag.id)) {
          blogTag.push(currentTag);
        }
      }

      return (
        <Col key={`post-${blog.id}`}>
          <strong>{dateFormat(blog.date, "mmmm dS, yyyy")}</strong>
          <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none' }}>
            <h2>{blog.title.rendered}</h2>
          </Link>
          {
          <p dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered.substring(0,blog.excerpt.rendered.indexOf('&hellip;')).concat(' <a style="color:blue" href="/blog/'+blog.id+'">[read more]</a>')}}/>
          
          //<p dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered.replace('[&hellip;]', '<a style="color:blue" href="/blog/'+blog.id+'">[read more]</a>')}}/>
          }
          <div>
            <TagList tagCollection={blogTag} /> 
          </div>
        <hr/>
        </Col>
      )
      })}
    </div>
  )
}

export default BlogList
