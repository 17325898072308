import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import './style.scss'

const WhatWeDoTabBar = () => {
  const { pathname } = useLocation()
  const path = pathname.replace('/what-we-do/', '')
  const activeTab = path === '/what-we-do' ? 'innovate' : path
  return (
    <div className="what-we-do-tabbar">
      <Link className={classNames({ active: activeTab === 'innovate' })} to="/what-we-do/innovate">Innovate</Link>
      <Link className={classNames({ active: activeTab === 'transform' })} to="/what-we-do/transform">Transform</Link>
      <Link className={classNames({ active: activeTab === 'activate' })} to="/what-we-do/activate">Activate</Link>
    </div>
  )
}

export default WhatWeDoTabBar
