import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './style.scss'

const TagList2 = ({tagCollection, type}) => {

  return(
    <div className={classNames('tag-list-2', type)}>
        <span><strong>Tags: </strong></span>
        {tagCollection?.map((tag, index) => (
        <span key={index} className="tag-name">
            <Link to={`/insights/tags?id=${tag.id}&name=${tag.name}`} style={{ display: 'inline-block' }}>
                {tag.name}
            </Link>
        </span>
        ))}
    </div>
  )
}

export default TagList2