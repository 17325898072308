import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet';
import { Container, Button } from 'react-bootstrap'
import {
  Switch,
  Route,
  useLocation,
  Link,
  // useHistory
} from 'react-router-dom'
import classNames from 'classnames'
import LatestContent from './LatestContent'
import MarketingMentors from './MarketingMentors'
import CustomerStories from './CustomerStories'
import Articles from './Articles'
import AquientNews from './AquientNews'
import Webinars from './Webinars'
import MainLayout from '../../layouts/MainLayout.js/index.js'
import ScrollToTopOnMount from '../../routers/ScrollToTopOnMount.js'


import ArticlesBanner from '../../assets/banners/Articles.png'
import CustStoryBanner from '../../assets/banners/Customer_Stories.png'
import MarketingMentorBanner from '../../assets/banners/Marketing_Mentors.png'
import LatestContentBanner from '../../assets/banners/Latest_Content.jpg';
import NewsBanner from '../../assets/banners/Aquient_news.png'
import WebinarBanner from '../../assets/banners/Webinars.png'

import MobArticlesBanner from '../../assets/banners/Articles_Mobile.png'
import MobCustStoryBanner from '../../assets/banners/Customer_Stories_Mobile.png'
import MobMarketingMentorBanner from '../../assets/banners/Marketing_Mentors_Mobile.png'
import MobLatestContentBanner from '../../assets/banners/Latest_Content_Mobile.png';
import MobNewsBanner from '../../assets/banners/News_MobileBanner.png'
import WebinarMobBanner from '../../assets/banners/Webinars_mobile.png'

import Background1 from '../../components/Backgrounds/LatestContent';
import Background2 from '../../components/Backgrounds/MarketingMentor';
import Background3 from '../../components/Backgrounds/CustomerStories';
import Background4 from '../../components/Backgrounds/Articles';

import './style.scss'
import './single-post.scss'

const TabPannels = [
  {
    key: 'latest-content',
    path: '/insights',
    label: 'LATEST CONTENT',
    title: 'Marketing Mentors by Aquient',
    tabTitle: 'Latest Content',
    desc: "In Marketing Mentors, we'll regularly be bringing interviews, news, advice and best practices on all things customer experience and enterprise growth marketing related.",
    button: {
      text: 'GO TO EPISODES',
      link: '/insights'
    },
    imageBanner: LatestContentBanner,
    mobImageBanner: MobLatestContentBanner,
    enable: true,
    banner: <Background1 />
  },
  {
    key: 'marketing-mentors',
    path: '/insights/marketing-mentors',
    label: 'PODCAST',
    title: 'Marketing Mentors by Aquient',
    tabTitle: 'Marketing Mentors',
    desc: "In Marketing Mentors, we'll regularly be bringing interviews, news, advice and best practices on all things customer experience and enterprise growth marketing related.",
    button: {
      text: 'GO TO EPISODES',
      link: '/insights/marketing-mentors'
    },
    imageBanner: MarketingMentorBanner,
    mobImageBanner: MobMarketingMentorBanner,
    enable: true,
    banner: <Background2 />
  },
  {
    key: 'cust-stories',
    path: '/insights/customer-stories',
    label: 'CUSTOMER STORIES',
    title: 'Aquient partners with Razor',
    tabTitle: 'Customer Stories',
    desc: "One year on from engaging Aquient to support its customer retention initiatives, Razer, the leading global lifestyle brand for gamers, have grown their subscriber base by over 85% and seen engagement rates 50% higher than they were a year ago.",
    button: {
      text: 'VIEW FULL CUSTOMER STORIES',
      link: '/insights/customer-stories'
    },
    imageBanner: CustStoryBanner,
    mobImageBanner: MobCustStoryBanner,
    enable: true,
    banner: <Background3 />
  },
  {
    key: 'articles',
    path: '/insights/articles',
    label: 'ARTICLE',
    title: 'Thoughts from the minds within',
    tabTitle: 'Articles',
    desc: "Collecting the thoughts and insights of our people, regarding the current state of affairs in the industry and beyond.",
    imageBanner: ArticlesBanner,
    mobImageBanner: MobArticlesBanner,
    enable: true,
    banner: <Background4 />
  },
  {
    key: 'webinars',
    path: '/insights/webinars',
    label: 'WEBINARS',
    title: '',
    tabTitle: 'Webinars',
    desc: "",
    imageBanner: WebinarBanner,
    mobImageBanner: WebinarMobBanner,
    enable: true,
    banner: <Background4 />
  },
  {
    key: 'aquient-news',
    path: '/insights/aquient-news',
    label: 'NEWS',
    title: 'Catch up on the latest news from Aquient',
    tabTitle: 'News',
    desc: "",
    imageBanner: NewsBanner,
    mobImageBanner: MobNewsBanner,
    enable: true,
    banner: <Background4 />
  },

  // {
  //   key: 'videos',
  //   path: '/insights/videos',
  //   label: 'WEBINAR',
  //   title: 'Some Webinar Title that is captivating',
  //   tabTitle: 'Videos',
  //   desc: "Description of webinar or video",
  //   imageBanner: '',
  //   enable: false
  // },
  // {
  //   key: 'ebooks',
  //   path: '/insights/e-books',
  //   label: 'E-BOOKS',
  //   title: 'Free E-books from us just take it',
  //   tabTitle: 'E-Books',
  //   desc: "What books Jimmy and the team at Aquient highly praises for everyone to read to become master of their respective domains",
  //   imageBanner: '',
  //   enable: false
  // },
  // {
  //   key: 'press-release',
  //   path: '/insights/press-release',
  //   label: 'PRESS RELEASE',
  //   title: 'Like a button, you press and release',
  //   tabTitle: 'Press Release',
  //   desc: "The latest press release on what is going on with Aquient, who we are working with and what more exciting things to come for the future",
  //   imageBanner: '',
  //   enable: false
  // },
]

const Insight = React.memo((props) => {
  let { pathname } = useLocation()
  // const history = useHistory()
  const [content, setContent] = useState({
    key: 'latest-content',
    path: '/insights',
    label: 'LATEST CONTENT',
    title: 'Marketing Mentors by Aquient',
    tabTitle: 'Latest Content',
    desc: "In Marketing Mentors, we'll regularly be bringing interviews, news, advice and best practices on all things customer experience and enterprise growth marketing related.",
    button: {
      text: 'GO TO EPISODES',
      link: '/insights'
    },
    imageBanner: LatestContentBanner,
    mobImageBanner: MobLatestContentBanner,
    enable: true,
    banner: <Background1 />
  })

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight, });

  useEffect(() => {
    function updateSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  const mobileSize = 575;

  useEffect(() => {
    const page = TabPannels.filter((item) => item.path === pathname)
    if (page.length > 0) {
      setContent(page[0])
    } else {
      setContent({
        key: 'latest-content',
        path: '/insights',
        label: 'LATEST CONTENT',
        title: 'Marketing Mentors by Aquient',
        tabTitle: 'Latest Content',
        desc: "In Marketing Mentors, we'll regularly be bringing interviews, news, advice and best practices on all things customer experience and enterprise growth marketing related.",
        button: {
          text: 'GO TO EPISODES',
          link: '/insights'
        },
        imageBanner: LatestContentBanner,
        mobImageBanner: MobLatestContentBanner,
        enable: true,
        banner: <Background1 />
      })
    }
  }, [pathname])

  // const scrollToTab = () => {
  //   const element = document.getElementById('scroll-target')
  //   const offset = 100
  //   const elementPosition = element.offsetTop
  //   const offsetPosition = elementPosition - offset
  //   window.scrollTo({
  //       top: offsetPosition,
  //       behavior: "smooth"
  //   })
  // }

  // const buttonHeroClick = (key) => {
  //   if (key === 'latest-content') {
  //     history.push('/insights/marketing-mentors')
  //     setTimeout(() => {
  //       scrollToTab()
  //     }, 500)
  //   } else {
  //     scrollToTab();
  //   }
  // }

  return (
    <div className={`insight-page ${content.key}`}>
      <ScrollToTopOnMount />
      <MainLayout>
        <Helmet>
          <title>Aquient | Insights</title>
          <meta name="description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          {/* Open Graph tags */}
          <meta name="og:url" content="https://aquient.asia/insights" />
          <meta name="og:title" content="Aquient | Insights"/>
          <meta name="og:description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Aquient | Insights" />
          <meta name="twitter:description" content="Collecting the thoughts and insights of our people,
                regarding the current state of affairs in the industry and beyond." />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <div>
          <div className={`static-banner ${content.key}`}>
            <img src={`${windowSize.width >= mobileSize ? content.imageBanner : content.mobImageBanner}`} alt={`static-banner-${content.key}`} />
          </div>
          {/* {content.banner} */}
          {/* <div
            className={`banner ${content.key}`}
            style={content.imageBanner !== '' ? {
              // background: `url(${content.imageBanner})`
              background: `rgba(255, 255, 255, .3) url(${content.imageBanner})`
            } : {
              background: 'none'
            }}
          >
            <div className="content">
              <span>{content.label}</span>
              <h1 className="text-center">{content.title}</h1>
              <p className="text-center">
                {content.desc}
              </p>
            </div>
          </div> */}
          <Container fluid className="tab-pannel-wrapper">
            <h2>{content.tabTitle}</h2>
            {content.tabTitle === 'News' ? <p>Catch up on the latest news from Aquient</p> : <p>See Aquient's latest insights on customer experience, enterprise growth marketing and more</p>}
          
            {TabPannels.map((item) => {
              return (
                <Button
                  as={Link}
                  to={item.path}
                  key={item.key}
                  className={classNames('insight-tab-btn', {
                    current: item.path ===  pathname,
                    disable: !item.enable,
                    [item.key]: true
                  })}
                >
                  {item.tabTitle}
                </Button>
              )
            })}
            <div id="scroll-target" />
            <div className="separator" />
            <div className="tab-content">
              <Switch>
                <Route exact path='/insights'><LatestContent /></Route>
                <Route exact path='/insights/'><LatestContent /></Route>
                <Route path='/insights/marketing-mentors'><MarketingMentors /></Route>
                <Route path='/insights/customer-stories'><CustomerStories /></Route>
                <Route path='/insights/articles'><Articles /></Route>
                <Route path='/insights/webinars'><Webinars /></Route>
                <Route path='/insights/aquient-news'><AquientNews /></Route>
                {/* <Route path='/insights/videos'><Video /></Route>
                <Route path='/insights/e-books'><Ebooks /></Route>
                <Route path='/insights/press-release'><PressRelease /></Route> */}
                <Route path='/insights/videos'><span className="coming-soon">Coming Soon..</span></Route>
                <Route path='/insights/e-books'><span className="coming-soon">Coming Soon..</span></Route>
                <Route path='/insights/press-release'><span className="coming-soon">Coming Soon..</span></Route>
              </Switch>
            </div>
          </Container>
        </div>
      </MainLayout>
    </div>
  )
})

export default Insight
