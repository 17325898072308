import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import salesforceLogo from '../../../assets/images/sitecore-logo.jpg'
import img1 from '../../../assets/images/platform1.png'
import './style.scss'

const Sitecore = () => {
  return (
    <div className="platform-sitecore">
      <hr />
      <Container>
        <div className="w-100 text-center">
          <img className="logo" src={salesforceLogo} alt="" />
        </div>
        <Row>
          <Col md={6}>
            <p className="mb-1"><b>Sitecore Development</b></p>
            <p>Our dedicated team of developers cover Sitecore and many other open source and bespoke
              solutions. Their technical knowledge combined with the strategic thinking of our Sitecore
              marketeers makes scoping any solution or migration completely robust. We can deliver projects
              of any scale in its entirety or collaborate with in-house teams to deliver a compelling solution.
            </p>
            <p>We have been recognised for delivering the highest level of technical competence and expertise.</p>
          </Col>
          <Col md={6}>
            <p className="mb-1"><b>Sitecore Business Optimisation Services</b></p>
            <p>Our Business Optimisation specialists will get you up and running with personalisation
              and optimisation for your existing website quickly and efficiently and unlock the power
              of the Sitecore platform.
            </p>
            <p>We offer our own version of SBOS workshops developed based on the experience of
              Sitecore’s. During these workshops we’ll help you align Sitecore marketing goals with
              your business objectives, define your customer segments and devise personalisation and
              optimisation plan and campaigns.
            </p>
          </Col>
        </Row>
      </Container>

      <hr />

      <Container>
        <Row>
          <Col md={6}>
            <p><b>Personalisation Using Sitecore</b></p>
            <p>We have developed our own framework for delivering high impact personalisation
              campaigns using Sitecore.
            </p>
            <p>As a result of our work, we have delivered tangible results for our clients, covering
              increases in sales, in sign ups, bookings, applications and overall conversions.
            </p>
          </Col>
          <Col md={6}>
            <p><b>Extending Sitecore</b></p>
            <p>There are instances where Sitecore’s out-of-the box functionality does not meet a
              client’s requirements. We have vast experience at extending the underlying system.
            </p>
            <p>For example, we’ve built an advanced banner management module which supports multiple
              image resolutions, impression caps and aggregated reporting in xDB. Our developers have
              pioneered the use of Sitecore for single page applications using ASP.NET SignalR library.
            </p>
          </Col>
        </Row>
      </Container>

      <div className="bg-red">
        <Container className="text-center">
          <p className="fz-37">Our dedicated team of developers technical knowledge combined with
            the strategic thinking of our Sitecore marketeers makes scoping any solution or
            migration completely robust.
          </p>
        </Container>
      </div>

      <hr className="mt-5"/>

      <Container>
        <Row>
          <Col md={6}>
            <p><b>Integration of Content &amp; Commerce Systems</b></p>
            <p>We help our customers to natively integrate content with commerce systems. Through our
              experience of Sitecore Commerce Connect we can help you create high converting and
              engaging retail experiences that combine personalised content with product recommendations
              based on the user’s shopping habits. Our mission is to help companies to start leveraging
              the power of contextual commerce.
            </p>
          </Col>
          <Col md={6}>
            <p><b>Sitecore Hosting &amp; Licensing</b></p>
            <p>Our technical team can help you design scalable hosting configurations to match your
              budget and needs. From cost efficient single server solutions to robust enterprise
              level infrastructure comprising multiple servers, real time replication and failover
              solutions, we will help you define and design the right hosting option for your project.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <p><b>Sitecore Upgrades</b></p>
            <p>Latest version of Sitecore, from Sitecore 9 onwards bring significant improvements
              in marketing, data, automation and customer experience management, its architecture
              is decentralised which often makes the upgrade process challenging and resource
              intensive. The latest Sitecore versions use a completely new technology stack. Before
              approaching an upgrade we will conduct an assessment of hosting infrastructure update
              as a prerequisite requirement of Sitecore 9.
            </p>
          </Col>
          <Col md={6}>
            <img src={img1} alt="" />
          </Col>
        </Row>
      </Container>

      <hr />

      <Container className="service-container pt-0">
        <p className="know-more text-center mt-5">Want to know more? Book your personal consultation
          with one of our Sitecore experts today to see how we can help your business.
        </p>
        <Button
          as={Link}
          to={{
            pathname: '/contact-us',
            state: {
              subject: 'Consultation with Sitecore expert',
              description: 'Please contact me using the details above regarding consultation on Sitecore.'
            },
          }}
          className="mt-5"
        >
          Book Now<i className="fas fa-chevron-right" />
        </Button>
      </Container>
    </div>
  )
}

export default Sitecore
