import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Img1 from '../../../assets/images/MarTech.jpg'
import './style.scss'

const MarketingTechnology = () => {
  return (
    <div id="marketing-technology" className="marketing-technology">
      <div className="bg-lightgrey">
        <Container>
          <Row>
            <Col lg={6}>
              <h6 className="text-uppercase">PRACTICE AREAS</h6>
              <h1>Marketing Technology</h1>
              <p><b>Technology tools are important, but just investing in tools isn’t enough.</b></p><br/>
              <p>
                At Aquient, we say that buying and implementing a new technology tool is like
                buying a gym membership. The membership alone won’t get you fit. You need to
                actually consistently go to the gym to achieve that goal. Aquient’s role is
                as a company’s personal trainer – we help define a digital-first future, help
                you change the way you work and encourage to keep improving via an ongoing
                process of improvement: test and learn.
              </p>
              <p>
                We are a strategic partner that is focused on helping you leverage technology
                to fuel growth and enable next-generation services and experiences. We do this
                by partnering closely with your technology organisaiton to create and support
                the strategic vision for your business through design of the necessary
                architecture, process and team structures to implement change.
              </p>
              <p>
                From technology strategy through development and innovation, we bring a
                customer-centric approach to all of our technology offerings, seamlessly
                integrating technology, data and design.
              </p>
                We know that no two technology implementations are the same. There are no
                cookie-cutters. Strong and efficient engineering is critical to our culture of
                success and discovering new solutions as technology evolves – helping our clients
                stay competitive in today’s digital-first economy.
            </Col>
            <Col lg={6}>
              <div className="img-container d-none d-lg-block"><img src={Img1} alt="" /></div>
              <ul>
                <li>Technology roadmapping</li>
                <li>Digital capabilities assessment</li>
                <li>Platform evaluation &amp; selection</li>
                <li>Tech innovation enablement</li>
                <li>Optimization &amp; quick wins</li>
                <li>Agile organizational transformation</li>
                <li>Product development</li>
                <li>Design system</li>
                <li>Software architecture</li>
                <li>DevOps</li>
                <li>Cloud security</li>
                <li>Artificial Intelligence</li>
                <li>Data engineering</li>
                <li>Innovation acceleration</li>
                <li>Creative engineering</li>
                <li>IoT</li>
                <li>Conversational Interfaces</li>
                <li>AR / VR</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-yellow">
        <Container className="text-center">
          <h6>THE TOOLS ARE JUST THE BEGINNING</h6>
          <h2 className="font-normal mt-4">
            You’ve got the gym membership, now you need the personal trainer
          </h2>
        </Container>
      </div>
    </div>
  )
}

export default MarketingTechnology
