import React, { useEffect, useState} from 'react'
import { getPostsByCategory, getAllTags, getCategoryId } from '../../../actions'
import ItemLayoutB from '../../../components/Insights/ItemLayoutB'
import Pagination from '../../../components/Pagination'
import { ReactComponent as ArticleIcon } from '../../../assets/icons/insights/article.svg'
import LoadingGif from '../../../assets/icons/loading.gif'

import './style.scss'


const AquientNews = () => {
    const [ data, setData ] = useState([]);
    const [ tags, setTag ] = useState([]);
    const [isFetched, setIsFetched] = useState(false);
    const [argsPage, setArgsPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryId, setCategoryId] = useState(0);
    const argsPerPage = 20;
    const perPage = 5;

    useEffect(() => {
        (async function() {
          const tags = await getAllTags();
          if(tags) {
            setTag(tags);
          }
          const id = await getCategoryId('aquient-news');
          console.log(id)
          if (id) {
            setCategoryId(id)
            const data = await getPostsByCategory(id, argsPage, argsPerPage);
            if(data && data.length) {
              setData(data);
              setArgsPage(argsPage + 1)
              
            } else {
              setIsFetched(true);
            }
          } else {
            setIsFetched(true);
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


      useEffect(() => {
        // to get all posts per page until empty
        if (argsPage > 1) {
          (async function() {
            const res = await getPostsByCategory(categoryId, argsPage, argsPerPage);
            if (res && res.length) {
              setData([...data, ...res])
              setArgsPage(argsPage + 1)
            }
            else {
              setIsFetched(true);
            }
          })()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [argsPage])

      const onPageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div className="aquient-news-tab">
      {!isFetched && (
        <div className="loadingWrapper">
          <img src={LoadingGif} alt="loading" />
        </div>
      )}
      {isFetched && data.map((item, i) => {
        if (
          (currentPage === 1 && i < currentPage * perPage) || 
          (currentPage !== 1 && i < currentPage * perPage && i >= (currentPage - 1) * perPage)
        ) {
          return (
            <>
              <ItemLayoutB
                key={item.id}
                type="aquient-news"
                item={item}
                tags={tags}
                isFirst={i === 0}
                link1={`/insights/aquient-news/${item.slug}`}
              />
              <div className="separator" />
            </>
          );
        }
        return null
      })}
      {isFetched && !data.length && (
        <div className="empty-post">
          <ArticleIcon />
          <h3>No Posts Yet</h3>
        </div>
      )}
      {!!data.length && (
        <Pagination
          currentPage={currentPage}
          totalPage={Math.ceil(data.length / perPage)}
          onChange={onPageChange}
        />
      )}
    </div>
  )
}

export default AquientNews