import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from "react-slick";
import img1 from '../../../assets/images/MLCarousell1.jpg'
import img2 from '../../../assets/images/MLCarousell2.jpg'
import img3 from '../../../assets/images/MLCarousell3.jpg'
import img4 from '../../../assets/images/machine-learning.png'
import { ReactComponent as Chat } from '../../../assets/icons/chat-question.svg'
import { ReactComponent as Internet } from '../../../assets/icons/internet.svg'
import { ReactComponent as Learning } from '../../../assets/icons/learning.svg'
import { ReactComponent as ArrowCircleRight } from '../../../assets/icons/arrow-circle-right.svg'
import { ReactComponent as ArrowCircleLeft } from '../../../assets/icons/arrow-circle-left.svg'
import List from '../../../components/List'
//import Img1 from '../../../assets/images/whatwedo1.png'
import './style.scss'

const MachineLearning = () => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <ArrowCircleRight fill="#F0F0F0" />,
    prevArrow: <ArrowCircleLeft fill="#F0F0F0" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };
  return (
    <div id="machine-learning" className="machine-learning">
      <Container>
        <Row className="align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <h6 className="text-uppercase">PRACTICE AREAS</h6>
            <h1>Machine Learning</h1>
            <p>
              <b>We are obsessed with data.</b> Our extensive experience, cutting edge technology and
              passion for machine learning will help get your ML projects off the ground successfully.
            </p>
          </Col>
          <Col lg={6} className="mb-5 mb-lg-0">
            <img src={img4} alt="" />
          </Col>
        </Row>

        <Row className="flex-wrap justify-content-md-center phase-container">
          <Col lg={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><Chat /></div>
            <h5>PHASE 1</h5>
            <p className="fz-37">Discovery</p>
            <List
              items={['Research', 'Collection', 'Business Understanding', 'Data Understanding', 'Data Preparation']}
              icon={<ArrowCircleRight fill="#15b3c8"/>}
            />
          </Col>
          <Col lg={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><Learning /></div>
            <h5>PHASE 2</h5>
            <p className="fz-37">Innovation</p>
            <List
              items={['Data Science', 'Feature Selection', 'Model Training', 'Model Validation']}
              icon={<ArrowCircleRight fill="#15b3c8"/>}
            />
          </Col>
          <Col lg={4} className="mt-5 text-center">
            <div className="mb-4 pie-chart-icon"><Internet /></div>
            <h5>PHASE 3</h5>
            <p className="fz-37">It’s Really Happening</p>
            <List
              items={['Research', 'Change Model', 'Deployment', 'Update and Tuning']}
              icon={<ArrowCircleRight fill="#15b3c8"/>}
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12}><h2 className="w-100 text-lg-center my-5">Machine Learning Discovery Sessions</h2></Col>
          <Col lg={6} className="mb-5">
            <h2 className="font-normal">
              Aquient’s Machine Learning Discovery (MLD) Sessions helps customers who are new to
              machine learning to understand machine learning concepts and identify potential
              business problems that can be solved using them.
            </h2>
            <p>
              This service includes an overview session, use case identification workshop, interviews
              with key stakeholders, technical assessment and an executive summary report.
            </p>
          </Col>
          <Col lg={6}>
            <h2>How?</h2>
            <p><b>Machine Learning Overview:</b> Review machine learning concepts and objectives</p>
            <p>Stakeholder Interviews: Gather information on data set location, access and characteristics</p>
            <p>
              <b>Recommendations:</b> Prepare executive report for customer on findings and recommended next steps
              for machine learning strategy on business use cases and review key learnings. Discuss the next
              steps for technical implementation.
            </p>
            <p>
              <b>Use Identification Workshop:</b> Facilitate group ideation with key line of business owners
              and stakeholders to identify at least three viable machine learning use cases.
            </p>
            <p>
              <b>Technical Assessment:</b> Conduct high level feasibility analysis of proposed machine learning use
              cases relative to data set capabilities and limitations.
            </p>
          </Col>
        </Row>
        <Slider {...settings}>
          <img src={img1} alt="ML1" />
          <img src={img2} alt="ML1" />
          <img src={img3} alt="ML1" />
        </Slider>
      </Container>
    </div>
  )
}

export default MachineLearning
