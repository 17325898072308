import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getAllPosts, getAllTags, getAllCategories } from '../../actions'
import { getCategory, getDetailLink, getCategorySlug } from '../../helpers'
import ItemLayoutA from '../Insights/ItemLayoutA'
import './style.scss'

const YouMightLike = () => {
  const [data, setData] = useState([]);
  const [tags, setTag] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async function() {
      const data = await getAllPosts();
      const tags = await getAllTags();
      if (data) {
        setData(data);
      }
      if (tags) {
        setTag(tags);
      }
      const categories = await getAllCategories();
      setCategories([...categories])
    })()
  }, [])

  return (
    <div className="you-might-like">
      <h4>YOU MIGHT LIKE...</h4>
      <Row>
        {data.map((item, i) => {
          if (i >= 3) return null;
          return (
            <Col md={4}>
              <ItemLayoutA
                key={item.id}
                type={getCategory(getCategorySlug(item.categories, categories))}
                item={item}
                tags={tags}
                link1={getDetailLink(getCategorySlug(item.categories, categories), item.slug, item.link)}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default YouMightLike