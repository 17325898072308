import React from 'react'
import classNames from 'classnames'
import './style.scss'

const Pagination = ({onChange, currentPage, totalPage = 0, className}) => {

  return(
    <div className={classNames('Pagination', className)}>
      <div className="dot" />
      {[...Array(totalPage)].map((e, i) => (
        <span
          className={classNames('',{active: currentPage === i + 1})}
          onClick={() => {
            onChange(i + 1)

            const element = document.getElementById('scroll-target')
            const offset = 100
            const elementPosition = element.offsetTop
            const offsetPosition = elementPosition - offset
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            })
          }}
        >
          {i + 1}
        </span>
      ))}
      <div className="dot" />
    </div>
  )
}

export default Pagination