import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as ContentConfig } from '../../../assets/icons/content-config.svg'
import { ReactComponent as ContentCreation } from '../../../assets/icons/content-creation.svg'
import { ReactComponent as Contract } from '../../../assets/icons/contract.svg'
import { ReactComponent as Science } from '../../../assets/icons/data-science.svg'
import { ReactComponent as Deal } from '../../../assets/icons/deal.svg'
import { ReactComponent as Dialogues } from '../../../assets/icons/dialogues.svg'
import { ReactComponent as Lifecycle } from '../../../assets/icons/lifecycle-marketing.svg'
import { ReactComponent as Mobile } from '../../../assets/icons/mobile-feature.svg'
import { ReactComponent as Monitor } from '../../../assets/icons/monitor.svg'
import { ReactComponent as Profile } from '../../../assets/icons/profile.svg'
import Img1 from '../../../assets/images/activate1.png'
import Img2 from '../../../assets/images/activate2.png'
import Img3 from '../../../assets/images/activate11.png'
import Img4 from '../../../assets/images/activate21.png'
import './style.scss'

const WhatWeDoActivate = () => {
  return (
    <div className="wwd-activate">
      <div className="bg-blue">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <h1 className="crit-title">Activate</h1>
              <p className="mb-5"><b>Create loyal customers and increase growth</b></p>
            </Col>
            <Col lg={6}>
              <p>
                To make 1:1 interactions with your customers feel intimate at scale, you need a zoomed
                in view of your customers. We use data science to understand people’s intent and
                predispositions. This combines behavourial data with content and technology to deliver
                these highly personalised interactions. By gaining progressively deeper insights about
                your customers, brands that work with us <b className="text-yellow">drive business results through creating
                lifelong, devoted advocates.</b>
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>SERVICE</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><ContentConfig /></div>
            <p><b>Customer Data Management &amp; Audience Strategy</b></p>
            <p>
              Need help keeping track of your customer information? Let us help. Use data to segment
              consumers and find the right person on the right device at the right moment.
            </p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Lifecycle /></div>
            <p><b>Lifecycle Marketing</b></p>
            <p>
              Create a co-ordinated ‘always-on’ contact strategy for the whole customer lifecycle to
              boost conversions and retention
            </p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><ContentCreation /></div>
            <p><b>Content Creation</b></p>
            <p>Content strategy</p>
            <p>Long-form editorial</p>
            <p>Creative concepting</p>
            <p>Photo &amp; art production</p>
            <p>Social content</p>
            <p>Post-production</p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Contract /></div>
            <p><b>Reporting &amp; Analytics</b></p>
            <p>
              Curate and organise your data into meaningful and actionable insights that are easy to
              understand and present.
            </p>
          </Col>
        </Row>
      </Container>

      <img className="d-lg-none img-mobile" src={Img3} alt="" />

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>STRATEGY &amp; PRODUCT</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col sm={6} lg={3} className="mt-5 col-img-1 d-none d-lg-block">
            <img src={Img1} alt="" />
          </Col>
          <Col sm={6} md={4} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Science /></div>
            <p><b>Data Science</b></p>
            <p>Data strategy</p>
            <p>Data visualisation</p>
            <p>Data mining &amp; modelling</p>
            <p>Data monetisation</p>
            <p>Brand &amp; social analytics</p>
            <p>Platform implementation</p>
            <p>Machine learning &amp; AI</p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Profile /></div>
            <p><b>Service Design</b></p>
            <p>User personas &amp; scenarios</p>
            <p>Customer experience</p>
            <p>Customer Journey Mapping</p>
            <p>Blueprint &amp; Storyboarding</p>
            <p>Business process design</p>
            <p>Technology strategy</p>
          </Col>
          <Col sm={6} lg={3} className="mt-5 col-img-2 d-none d-lg-block">
            <img src={Img2} alt="" />
          </Col>
        </Row>
      </Container>

      <img className="d-lg-none img-mobile" src={Img4} alt="" />

      <Container className="service-container">
        <div className="title-line">
          <hr />
          <h6 className="text-center"><span>Marketing</span></h6>
        </div>
        <Row className="flex-wrap justify-content-center">
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Mobile /></div>
            <p><b>Campaigns</b></p>
            <p>Creative strategy</p>
            <p>Print &amp; Outdoor</p>
            <p>Branded content</p>
            <p>Display, mobile &amp; social</p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Dialogues /></div>
            <p><b>Social &amp; PR</b></p>
            <p>Social strategy</p>
            <p>Social campaigns</p>
            <p>Communications strategy</p>
            <p>Community management</p>
            <p>Influencer engagement</p>
            <p>Analytics</p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Monitor /></div>
            <p><b>Media</b></p>
            <p>Media design analysis</p>
            <p>Measurement &amp; optimisation</p>
            <p>Investment activation</p>
            <p>Attribution modelling</p>
          </Col>
          <Col sm={6} lg={3} className="text-center mt-5">
            <div className="text-center mb-4"><Deal /></div>
            <p><b>CRM</b></p>
            <p>CRM strategy</p>
            <p>Loyalty initiatives</p>
            <p>Marketing automation</p>
            <p>CRM ecosystem development</p>
            <p>Digital customer service</p>
            <p>Customer data management</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhatWeDoActivate
