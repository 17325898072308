import React from 'react'
import '../style.scss'

const Background3 = () => {
  return (
    <div className='banner insight-bg-3'>
        <div className="content">
            <span>Customer Stories</span>
            <h1 className="text-center">Aquient partners with Razor</h1>
            <p className="text-center">
                Razer, the leading global lifestyle brand for gamers, have grown their subscriber base by over 85%
                and increased engagements rates by 50% in less than a year.
            </p>
        </div>
        <div className="circle-left">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
            <div className="solid1" />
            <div className="solid2" />
          </div>
        </div>
        <div className="circle-right">
          <div className="wrapper">
            <div className="fill1" />
            <div className="fill2" />
            <div className="fill3" />
            <div className="solid1" />
            <div className="solid2" />
          </div>
        </div>
    </div>
  )
}

export default Background3
