import React from 'react'
import '../style.scss'

const Background2 = () => {
  return (
    <div className='banner insight-bg-2'>
        <div className="content">
            <span>Podcast</span>
            <h1 className="text-center">Marketing Mentors</h1>
            <p className="text-center">
                In Marketing Mentors, we'll be bringing you intervews, news, advise
                and best practices on all things customer experience and enterprise growth
                marketing related.
            </p>
        </div>
        <div className="circle-left">
          <div className="wrapper">
            <div className="blue" />
            <div className="pink" />
            <div className="yellow" />
          </div>
        </div>
        <div className="circle-right">
          <div className="wrapper">
            <div className="blue" />
            <div className="pink" />
            <div className="yellow" />
          </div>
        </div>
    </div>
  )
}

export default Background2
