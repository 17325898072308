const headerNavs = [
    {
        key: 'whatwedo',
        title: 'What we do',
        href: '/what-we-do',
        subnavs: [
            { key: 'ourapproach', href: '/what-we-do', title: 'Our Approach' },
            { key: 'practiceareas', href: '/practice-areas', title: 'Practice Areas' },
            { key: 'casestudies', href: '/case-studies', title: 'Case Studies' },
        ],
    },
    {
        key: 'platforms',
        title: 'Platforms',
        href: '/platforms',
        subnavs: [
            { key: 'salesforce', href: '/platforms/salesforce', title: 'Salesforce' },
            { key: 'sitecore', href: '/platforms/sitecore', title: 'Sitecore' },
            { key: 'googlecloud', href: '/platforms/google-cloud', title: 'Google Cloud' },
            { key: 'mulesoft', href: '/platforms/mulesoft', title: 'Mulesoft' },
        ],
    },
    {
        key: 'industries',
        title: "Industries",
        href: '/industries',
    },
    {
        key: 'careers',
        title: "Careers",
        href: '/careers',
    },
    {
        key: 'insight',
        title: "Insights",
        href: '/insights',
    },
    {
        key: 'news',
        title: "News",
        href: '/insights/aquient-news',
    },
]

export default headerNavs
