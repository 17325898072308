import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './style.scss'

const StoryList = ({ stories }) => {

  return (
    <Row className="story-list">
      {stories?.map(story => (
        <Col key={`case-${story.id}`} md={6} lg={4}>
          <Link to={`/case-studies/${story.id}`}>
            <Card className="w-100">
              <Card.Img variant="top" src={story.image1.guid} />
              <Card.Body>
                <Card.Title>{story.title.rendered}</Card.Title>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  )
}

export default StoryList
