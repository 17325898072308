import React, { Fragment, useState } from 'react'
import { NavDropdown, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import headerNavs from '../../../constants/headerNavs'
import { getIsActive } from '../../../helpers/index'
import './style.scss'

const ExpandNav = ({ nav }) => {
  const { pathname } = useLocation()
  // const getIsActive = (str) => pathname.includes(str)
  const isSubnavIncludesThisNav = () => {
    if (pathname === '/') return false
    if (pathname.includes(nav.href)) return true
    if (nav?.subnavs) {
      const tempNav = nav.subnavs.filter((subnav) => subnav.href.includes(pathname)) || []
      if (tempNav.length > 0) return true
    }
    return false
  }
  const [show, setShow] = useState(isSubnavIncludesThisNav())
  return (
    <>
      <Button
        className={classNames("mobile-link", {active: show })}
        onClick={() => setShow(!show)}
      >
        {nav.title}<i className="fas fa-chevron-down" />
      </Button>
      <div className={classNames("mobile-sublink-wrapper", {show: show || getIsActive(nav.href,pathname)})}>
      {nav.subnavs.map((subnav) => 
        <NavDropdown.Item
          key={`msn-${subnav.key}`}
          eventKey={subnav.key}
          as={Link}
          to={subnav.href}
          className={classNames("mobile-sublink", {active: getIsActive(subnav.href,pathname) })}
        >{subnav.title}
        </NavDropdown.Item>
      )}
      </div>
    </>
  )
}

const MobileNav = () => {
  const { pathname } = useLocation()
  // const getIsActive = (str) => pathname.includes(str)
  return (
    <NavDropdown
      alignRight
      title={<i className={classNames("fas fa-bars")} />}
      id="mobile-nav-dropdown"
      className="ml-auto d-md-none mobile-menu-button"
    >
      <div className="mobile-link-wrapper">
        {headerNavs.map((nav, i) => {
          if (nav.subnavs) return (
            <Fragment key={`mn-${nav.key}`}>
              <ExpandNav nav={nav} />
            </Fragment>
          )
          return (
            <NavDropdown.Item
              key={`mn-${nav.key}`}
              eventKey={nav.key}
              as={Link}
              to={nav.href}
              className={classNames("mobile-link", {active: getIsActive(nav.href,pathname) })}
            >{nav.title}
            </NavDropdown.Item>
          )
        })}
        <Link to="/contact-us" className={classNames("mobile-link", {active: getIsActive('/contact-us',pathname) })}>Contact Us</Link>
      </div>
    </NavDropdown>
  )
}

export default MobileNav
